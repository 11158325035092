/// BASELINE STYLES
/// =================================================

$sent-bubble-bg: #33aafc;
$received-bubble-bg: #ebebeb;
$bubble-color: #fff;
$cite-color: #818181;

.contentWrapper {
  background-color: #fff;
  border: #f4f4f4 1px solid;
  border-left: #fff;
}

/* =====/////// CHAT BUBBLE COMPONENT /////=====  */

.chatBubbleWrapper {
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto;
  margin-right: 18px;
}

.disable-button:disabled {
  pointer-events: none;
  border-color: #9C9C9C !important;
  color: #9C9C9C !important;
}
.disable-label {
  pointer-events: none;
  border-color: #9C9C9C !important;
  color: #9C9C9C !important;
  background-color: #FFF;
}

.chatBubbleWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chatBubbleWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.singleMessageWrapper {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.shared {
  position: relative;
  margin-bottom: 2%;
  line-height: 10px;
  word-wrap: break-word;
  border-radius: 25px;
  list-style-type: none;
}

.inboxV2Tail {
  position: absolute;
  left: -2px;
  bottom: 0px;
  z-index: 0;
}

.blueTail {
  position: absolute;
  bottom: 0px;
  right: -3px;
  z-index: 0;
}

.chatDate {
  display: block;
  padding: 0px 0px 0px 0px;
  position: absolute;
  right: 18px;
  bottom: 3px;
  z-index: 5;
}

.chatDateProduct {
  color: var(--Light, #BDBDBD);
  display: block;
  position: absolute;
  right: 12px;
  bottom: 2px;
  z-index: 5;
}

.chatDateSentLink {
  display: block;
    padding: 0px 0px 0px 0px;
    position: absolute;
    right: 5px;
    bottom: 0px;
}

.chatDateReceive  {
  display: block;
  padding: 0px 0px 0px 0px;
  position: absolute;
  right: 14px;
  bottom: 3px;
}

#sent {
  align-self: flex-end;
  color: white;
  background: $sent-bubble-bg;
  border-radius: 20px;
  padding: 8px 12px calc(0.6vw) 14px;

  &:before {
    right: -10px;
    background-color: $sent-bubble-bg;
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    border-bottom-left-radius: 10px;
  }
}

#sentLink {
  align-self: flex-end;
  color: white;
  background: #33aafc;
  border-radius: 20px;
  padding: 8px 12px 4px 14px;

  a {
    font-weight: normal;
    color: white !important;
    text-decoration: underline;
  }

  &:before {
    right: -10px;
    background-color: $sent-bubble-bg;
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    border-bottom-left-radius: 10px;
  }
}

.sent {
  line-height: 18px;
  text-align: left;
  max-width: 100%;
}

.sentTextDiv {
  display: flex;
  justify-content: flex-start;
  min-width: 55px;
  z-index: 10;
  position: relative;
}

#otherAsctSent {
  align-self: flex-start;
  color: white;
  background: #818181;
  border-radius: 25px;
  padding: 8px 12px 8px 14px;
  min-height: 20px;

  &:before {
    left: -10px;
    background-color: #818181;
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    left: -26px;
    border-bottom-right-radius: 10px;
  }
}

.received {
  position: relative;
  line-height: 15px;
  text-align: left;
}


#received {
  align-self: flex-start;
  color: black;
  background: $received-bubble-bg;
  border-radius: 25px;
  padding: 8px 12px 8px 14px;
  min-height: 20px;

  .received {
    a {
      font-weight: normal;
      color: black !important;
      text-decoration: underline;
    }
  }
}

.noTail {
  margin-bottom: 2px;

  &:before,
  &:after {
    opacity: 0;
  }
}

cite {
  letter-spacing: 0.5px;
  color: $cite-color;
  margin-bottom: 1%;
  font-size: 11px;
}

.invalid-number-text {
  background-color: #FF7A7A;
  color: white;
  font-style: italic;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.invalid-number-new-convo {
      position: absolute;
    margin-top: 550px;
}

/* =====/////// HEADER COMPONENT /////=====  */
.assignMenu {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;

  .ant-menu-submenu-title {
    padding-right: 0 !important;
  }

  .ant-menu-submenu-open {
    margin-top: 10%;
  }
}

.headingIcons {
  color: #818181;
  display: flex;
  margin-right: 12px;
  align-items: center;
}

.headingIcons > span {
  padding: 5px;
}

/* =====/////// Multi store location styles /////=====  */
.inboundStorePromptDiv{
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,.8);
  position: absolute;
  top: 75px;
  z-index: 100;
  padding: 21% 0%;
}
.inboundStorePromptRight{
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,.8);
  position: absolute;
  top: 75px;
  z-index: 100;
  padding: 45% 0%;
}
.inboundStorePromptButtons{
  margin-top: 30px;
  z-index: 101;
  position: relative;
  opacity: 1;
}
.inboundStorePromptButtonsRight{
  margin-top: 30px;
  z-index: 101;
  position: relative;
  opacity: 1;
  left: 12%
}

/* =====/////// FOOTER COMPONENT /////=====  */

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  margin-right: 13px;
    position: relative;
}

.fakeInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  margin-right: 13px;
    position: relative;
}

.inputButtons {
  margin-left: -40%;
  margin-top: 2%;
  margin-bottom: 2%;
}


/* ====///// OPTED OUT / DNC BANNER ///====  */
.unknown-number-banner {
  width: 100%;
  background: white;
  color: black;
  height: 40px;
  border-bottom: 1px solid black;
}

.facebook-closed-banner {
  width: 100%;
  background: black;
  color: white;
  max-height: 40px;
  font-style: italic;
  font-size: 12px;
  z-index: 15;

  p {
    text-align: center;
    line-height: 1;
  }
  span {
    font-weight: 600;
  }
}

.opted-out-banner {
  width: 100%;
  background: #3d3d3d;
  color: white;
  max-height: 40px;
}

.opted-out-banner p {
  text-align: center;
  line-height: 1;
}

.opted-out-banner-blocked {
  font-style: italic;
  font-size: 12px;
  z-index: 15;

  span {
    font-weight: 600;
  }

  i {
    text-decoration: underline;
    cursor: pointer;
  }
}

.chat-payment-modal-container-full {
  width: 348px;
  min-height: 572px;
  overflow-y: scroll;
  z-index: 99;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 50%;
  margin-top: -250px;
  margin-left: auto;
  margin-right: auto;
  padding: 36px 30px;
  left: 0;
  right: 0;
  text-align: center;
  cursor: default;
}

.chat-payment-modal-container-right {
  padding-top: 40px;
}

.chat-templates-modal-container-full {
  min-width: 300px;
  width: 460px;
  max-width: 500px;
  height: 65vh;
  overflow-y: auto;
  z-index: 99;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  padding: 30px 30px 24px;
  left: 50%;
  right: 0;
  text-align: center;
  cursor: default;
  overflow-y: hidden;
  overflow-x: hidden;

  > input {
    width: calc(100% - 60px);
    margin-left: 2px;
    height: 35px;
    margin-top: 8px;
  }

  > .searchInput {
    background-position: 20px 11.5px
  }
}

.chat-templates-modal-container-right {
  height: 100%;
  padding: 0px 10px 36px 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  > input {
    width: calc(100% - 60px);
    margin-left: 6px;
  }

  > .searchInput {
    background-position: 20px 11.5px
  }

}


.chat-collection-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  flex: 1;
}

.chat-product-modal-list{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}


.chat-product-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.chat-product-modal-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 44px;
  height: 66px;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  border-bottom: 0.5px solid rgba(129, 129, 129, 0.25);

  &:hover {
    background: #F2F3F6;
  }
}

.chat-product-modal-item-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 6px;
  overflow: hidden;
  border: 0.5px solid #BDBDBD;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.chat-product-modal-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 4px;

  > div {
    font-size: 12px;
    font-weight: 600;
  }

  > p {
    color: var(--form, #818181);
    font-size: 11px;
    margin: 0;
  }
}

.chat-templates-modal-container-right, .chat-templates-modal-container-full {
  .whatsapp-auto-container {
    padding-right: 8px;
  }

  .whatsapp-auto-container::-webkit-scrollbar {
    width: 6px;
  }

  .whatsapp-auto-container::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #F2F3F6;
  }

  .whatsapp-auto-container::-webkit-scrollbar-track:hover {
    background-color: #EDEEF1;
  }

  .whatsapp-auto-container::-webkit-scrollbar-track:active {
    background-color: #EAEBEE;
  }

  .whatsapp-auto-container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #BDBDBD;
  }

  .whatsapp-auto-container::-webkit-scrollbar-thumb:hover {
    background-color: #B8B8B8;
  }

  .whatsapp-auto-container::-webkit-scrollbar-thumb:active {
    background-color: #A8A8A8;
  }
}

.chat-templates-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 0 16px;
}

.chat-templates-modal-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.loading {
  font-size: 35px;
  line-height: 20px;
  color: #BDBDBD;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 30px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 30px;
  }
}

.showEllipses {
  display: inline-block
}
.noShowEllipses {
  display: none;
}

.chat-dropdown-menu {
  font-family: Poppins, sans-serif;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;

  .ant-dropdown-menu {
    border-radius: 4px;
    padding: 0;
  }

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-color: rgba($color: #818181, $alpha: 0.5) !important;

    &:hover {
      background-color: #E8F4FF;
    }

    & > img {
      width: 25px;
      margin-right: 0;
    }

    & > svg {
      width: 25px;
      margin-right: 0;
    }

    .ant-dropdown-menu-title-content {
      font-size: 13px;
      padding: 12px 12px 12px 6px;
    }
  }
}
