.products-v2-details-header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;

  &__title {
    flex: 1;
    font-weight: 600;
    font-size: 24px;
  }

  &__options {
    display: flex;
    gap: 8px;
  }
}
