.clients-v2-collection-product--card {
  width: 220px;
  box-sizing: border-box;
  border-radius: 8px;
}

.clients-v2-collection-product--card > .ant-card-body {
  padding: 0;
  box-sizing: border-box;
}

.clients-v2-collection-product--card-header {
  position: relative;
}

.clients-v2-collection-product--card-header img {
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
}

.clients-v2-collection-product--card-header-price {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  width: 86px;
  height: 22px;
  border-radius: 4px;
  background: #EBEBEB;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients-v2-collection-product--card-body {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px;
}

.clients-v2-collection-product--card-body-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  margin-bottom: 4px;
  text-align: left;
}

.clients-v2-collection-product--card-body-subtitle {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.clients-v2-collection-product--card-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  text-align: left;
}
