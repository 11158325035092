:root {
  --blue: #33aafc;
  --cbGrey: #f3f3f3;
  --darkGrey: #818181;
  --medGrey: #979797;
  --red: #d93b3b;
}

body *,
body *::before,
body *::after {
  box-sizing: content-box;
}

html body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 0.85em;
  /* height: 100vh; */
  /* margin: 0px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* added here */
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.575;
  font-feature-settings: "tnum", "tnum";
}

span {
  font-family: Poppins, sans-serif;
}

button {
  font-family: Poppins, sans-serif;
}

textarea,
input {
  font-family: Poppins, sans-serif;
}

textarea::placeholder,
input::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  height: 100vh;
  width: 100vw;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

legend {
  padding: 0;
}

body p {
  margin-top: 1em;
}

body input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: auto;
}

.react-datepicker .react-datepicker__navigation {
  height: 0px;
  width: 0px;
  top: 12px;
}

body ol,
body ul,
body dl {
  margin: 16px 0;
}

body button,
body button::before,
body button::after {
  box-sizing: border-box;
}

body .fa-1x {
  font-size: 1.2em;
}
