button.cb-button,
button.cb-button:focus {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 16px 24px;
  border-radius: 4px;
  cursor: pointer;
  color: #1769af;
  border: 2px solid #1769af;
}

button.cb-button span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  line-height: 20px;
  font-weight: 600;
  font-size: 13px;
}

button.cb-button span.anticon {
  font-size: 16px;
}

button.cb-button.ant-btn-primary,
button.cb-button.ant-btn-primary:focus {
  background-color: #1769af;
  color: #fff;
  border: none;
}

button.cb-button:hover {
  border: 2px solid #1769af;
  background: #1769af;
  color: #fff;
}

button.cb-button.ant-btn-primary:hover {
  background: #398bcf;
  color: #fff;
  border: none;
}

button.cb-button > svg + span {
  margin-left: 8px;
}

button.cb-button.ant-btn-link,
button.cb-button.ant-btn-link:focus {
  background-color: transparent;
  color: #231f20;
  border: none;
}

button.cb-button.ant-btn-link:hover {
  background-color: transparent;
  color: #398bcf;
  border: none;
}

button.cb-button[disabled],
button.cb-button[disabled]:hover,
button.cb-button[disabled]:focus,
button.cb-button[disabled]:active {
  background: #f5f5f5;
  cursor: not-allowed;
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  border: none;
}
