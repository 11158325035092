/* card component styles */
.dashboardMetrics_outerDiv {
  justify-content: flex-end;
  z-index: 10;
  position: relative;
  max-width: 1000px;
  display: flex;
  bottom: 120px;
}

.dashboardMetrics_card {
  max-width: 1000px;
  /* min-height: 340px; */
  /* padding: 75px 106px 24px 21px; */
  border-radius: 6px;
  border: solid 1px #bec8cc;
}

.dashboardMetrics_header_div {
  height: 57px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-left: 20px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353538;
}

/* card styles */
.dashboardMetrics_card_header_div {
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 11px 15px 0px 15px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353538;
  flex-wrap: nowrap;
}
.dashboardMetrics_card_content_div {
  display: flex;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
}
.dashboardMetrics_card_body_div {
  max-width: 1000px;
  /* min-height: 283px; */
  border-radius: 0px 0px 6px 0px;
  background-color: #f2f3f6;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
}

.card {
  width: 270px;
  height: 241px;
  /* margin: 5px 0px 0px 17px; */
  margin: 18px 0px 18px 17px;
  border-radius: 6px;
  border: solid 1px #bec8cc;
  background-color: #ffffff;
  position: relative;
  /* top: 15px; */
}

.card_number {
  height: 48px;
  font-family: Poppins;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353538;
}

.card_subHeader {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a7983;
  text-align: left;
}

.card_text {
  width: 64px;
  height: 15px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #6a7983;
}

/* small card */

.small_card {
  /* width: 270px; */
  width: 280px;
  height: 163px;
  border-radius: 6px;
  border: solid 1px #bec8cc;
  background-color: #ffffff;
  position: relative;
}

.small_card_percent_width {
  width: 110px;
}

.small_card_margin_top {
  margin: 20px 0px 20px 17px;
  /* margin: 20px 16px 20px 17px; */
}
.small_card_margin_bottom {
  margin: 0px 0px 20px 17px;
}

.viewReportDiv {
  margin: 0px 16px 8px 0px;
  font-size: 10px;
  width: 94%;
  height: 20px;
  position: absolute;
  display: flex;
  bottom: 0px;
  justify-content: right;
}

.viewReportAnchor {
  text-decoration: none;
  width: 60px;
  height: 20px;
  color: #1769af;
}

.metrics_card_margin {
  margin-top: -90px;
}

/* LOADING SPINNER */

.dash-ring {
  position: absolute;
  width: 270px;
  height: 238px;
  right: 0px;
  bottom: 3px;
  border-radius: 6px;
  background-color: #fff;
}

.dash-ring-small {
  position: absolute;
  width: 270px;
  height: 160px;
  /* right: 0px;
  bottom: 3px; */
  border-radius: 6px;
  background-color: #fff;
}

.dash-ring-sm-loc {
  right: 0px;
  bottom: 3px;
}

.dash-ring-button {
  position: absolute;
  background-size: contain;
  background-color: transparent;
}

#dashSpinOff {
  display: none;
}

#dashSpinOn {
  display: inline-block;
}

.dash-ring-button div {
  box-sizing: border-box;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 2.5px solid #fff;
  border-radius: 50%;
  animation: dash-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.dash-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 30px 30px 0px 80px;
  border: 6px solid #33aafc;
  border-radius: 50%;
  animation: dash-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #33aafc transparent transparent transparent;
}

.dash-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 70px 30px 85px 80px;
  border: 6px solid #33aafc;
  border-radius: 50%;
  animation: dash-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #33aafc transparent transparent transparent;
}
.dash-ring div:nth-child(1),
.dash-ring-small div:nth-child(1),
.dash-ring--button div:nth-child(1) {
  animation-delay: -0.45s;
}
.dash-ring div:nth-child(2),
.dash-ring-small div:nth-child(2),
.dash-ring--button div:nth-child(2) {
  animation-delay: -0.3s;
}
.dash-ring div:nth-child(3),
.dash-ring-small div:nth-child(3),
.dash-ring--button div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes dash-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* info bubble text */

.previousMetricNum {
  font-size: 34px;
  font-weight: 600;
  text-align: left;
  display: block;
  padding-left: 16px;
  line-height: 1;
  width: max-content;
  padding-right: 16px;
}
.previousMetricText {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  display: block;
  padding-left: 16px;
  line-height: 1;
  width: max-content;
  padding-right: 5px;
}

.arrowAnim {
  margin-left: 8px;
  animation: example 0.2s linear 0s 2 alternate;
}

@keyframes example {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(1.5px);
  }

  100% {
    transform: translateY(3px);
  }
}

@media only screen and (max-width: 1200px) {
  .dashboardMetrics_card_body_div {
    padding-bottom: 25px;
  }
  .small_card_margin_top,
  .small_card_margin_bottom {
    margin: 20px 0px 0px 20px;
  }

  .card {
    margin: 18px 0px 0px 17px;
  }
}
@media only screen and (max-width: 800px) {
  .metrics_card_margin {
    margin-top: -50px;
  }
  .dashboardMetrics_outerDiv {
    bottom: 75px;
    z-index: 0;
  }
}
