.cb-pagination-v3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.cb-pagination-v3__chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  font-size: 16px;
  text-align: center;
  color: #bdbdbd;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cb-pagination-v3__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  line-height: 22px;
  margin: 8px;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cb-pagination-v3__item:hover {
  color: #1890ff;
}

.cb-pagination-v3__item--selected {
  border-bottom: 2px solid #33aafc;
  color: #1890ff;
  font-weight: bold;
  font-size: 13px;
}

.cb-pagination-v3__short {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  margin: 8px 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 1px;
  text-align: end;
}

.cb-pagination-v3__short:hover {
  align-items: center;
}

.cb-pagination-v3__short .cb-pagination-v3__short--icon {
  display: none;
}

.cb-pagination-v3__short:hover .cb-pagination-v3__short--icon {
  color: #1890ff;
  display: flex;
}

.cb-pagination-v3__short:hover .cb-pagination-v3__short--dot {
  display: none;
}

.cb-pagination-v3__select {
  border-radius: 6px;
}

.cb-pagination-v3 .cb-pagination-v3-options {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
  justify-content: flex-start;
  flex: 1;
  gap: 16px;
}

.cb-pagination-v3 .cb-pagination-v3-options .ant-select .ant-select-selector {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px !important;
  border: 1px solid #818181;
  border-radius: 4px;
  height: 24px;
}

.cb-pagination-v3 .cb-pagination-v3-options .ant-select .ant-select-arrow {
 right: 11px;
}

.cb-pagination-v3 .cb-pagination-v3-options .cb-pagination-v3-options-quick-jumper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cb-pagination-v3 .cb-pagination-v3-options .cb-pagination-v3-options-quick-jumper input {
  width: 60px;
  padding: 4px 12px !important;
  border: 1px solid #818181;
  border-radius: 4px;
  height: 32px;
}
