.notifications {
  margin-bottom: 40px;
  &-divider {
    width: 100%;
    height: 0.5px;
    background-color: #9C9C9C;
    margin: 60px 0 40px;
  }

  &-header {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }

  &-title {
    margin-left: 8px;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    font-size: 12px;
    color: #9C9C9C;
  }
}
