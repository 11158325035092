.product-image-scroll {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.5;
}

.product-image-scroll:hover {
  opacity: 1;
}

.product-image-scroll-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-image-selected {
  width: 300px;
  height: 300px;
  border-radius: 6px;
  overflow: hidden;
}

.product-image-selected-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-image-scroll-selected {
  opacity: 1;
}

.product-details {
  display: grid;
  grid-template-columns: auto auto;
  text-align: left;
  row-gap: 6px;
}

.product-description {
  display: grid;
  grid-template-columns: 102px auto;
  text-align: left;
}
