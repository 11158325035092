.tab-header-card-btn {
  font-family: Poppins;
  border: none;
  height: 99%;
  width: 151px;
  margin-right: 2px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}
