.layout-main {
  padding: 17px 30px 39px 36px;
  height: 100vh;
  width: 100vw;
}
.layout-main-w-payment-banner {
  padding: 125px 30px 39px 36px;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
}

.standard-width-with-sidebar {
  padding-left: 230px;
}

.standard-width-wo-sidebar {
  /* padding-left: 110px; */
  padding-left: 30px;
}

.layout-header {
  height: auto;
  width: 100%;
}

.layout-header-description {
  height: auto;
  width: auto;
}

.layoutTitle {
  /* display: inline-block; */
  /* word-break: break-all !important; */
  /* word-wrap: break-word !important; */
  text-align: left;
  /* overflow-wrap: break-word !important; */
  min-width: 50px;
  /* white-space: pre-line;  */
  /* word-wrap: ; */
  /* height: 39px; */
  height: auto;
  font-family: Poppins;
  font-size: 28px;
  font-weight: bold;
  color: #353538;
  position: relative;
  left: 0px;
  top: -7px;
  width: auto;
}

.bread-crumb-arrows {
  margin-right: 15px;
  margin-left: 15px;
  letter-spacing: normal;
  font-weight: 500;
}

.completed-bread-crumb {
  color: #7fbd31;
  font-size: 10px;
  font-weight: 600;
  border-radius: 3px;
  background-color: #ecf8dd;
}

.current-bread-crumb {
  color: black;
  font-weight: 500;
}

.layoutBreadcrumb {
  left: 0px;
  top: 40px;
  color: #bdbdbd;
  font-size: 12px;
  text-align: left;
}

.layoutDesc {
  font-size: 14px;
  color: #818181;
  margin-top: 40px;
  text-align: left;
  margin-bottom: 2px;
}

.layoutMenus {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-start;
  width: 320px;
  height: 44px;
}

.store-menu-input {
  vertical-align: middle;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}

.store-menu-input input {
  width: 164;
  color: #a5a5a5;
  font-size: 14px;
  padding-left: 15px;
  font-family: Poppins;
  padding-right: 30px;
  outline: none;
}

.store-menu-input img {
  position: absolute;
  right: 20px;
  top: 18px;
  outline: none;
}
.rotateImg {
  transform: rotate(180deg);
}

.userMenuPic {
  display: inline-block;
  width: 46px;
  height: 50px;
  vertical-align: middle;
  outline: none;
}

.userMenuList {
  width: 169px;
  height: 100px;
  margin-top: 22px;
  background-color: #343434;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  float: right;
  position: relative;
  list-style-type: none;
  -webkit-padding-start: 0;
  z-index: 5;
}
.userMenuList li {
  color: #ffffff;
  height: 50%;
  line-height: 50px;
  vertical-align: middle;
}

.userMenuList li:hover {
  background-color: var(--blue);
  border-radius: 3px;
  cursor: pointer;
}
.userMenuList img {
  position: absolute;
  top: -12px;
  right: 12px;
}

.hamburger-menu-icon {
  display: none;
}

.smallScreenMenu {
  display: none;
}

.smallScreenMenu li {
  list-style: none;
}

.storeSelectMobile {
  background-color: #343434;
  border-radius: 3px;
  width: 100vw;
  -webkit-padding-start: 0;
  z-index: 2 !important;
  margin: 0px;
}

.storeSelectMobile li {
  text-align: center;
  text-indent: 22px;
  color: #ffffff;
  font-size: 14px;
  height: 45px;
  padding-top: 10px;
  line-height: 42px;
  vertical-align: middle;
  cursor: pointer;
  z-index: 2 !important;
  border-bottom: 1px solid #979797;
}

.storeSelectMobile li:hover {
  background-color: var(--blue);
  border-radius: 3px;
}

.mobileInput {
  width: 200px;
  height: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  float: left;
  margin-left: 35px;
  font-size: 14px;
  color: #ffffff;
}

.highlightPhoto {
  background-color: transparent;
  padding-top: 1px;
}
.highlightPhoto:hover {
  background-color: #e8f6ff;
  padding-top: 1px;
}

.standard-layout__back-btn {
  margin-right: 16px;
  color: #353538;
  cursor: pointer;
}

.standard-layout__title-image {
  max-width: 48px;
  max-height: 48px;
  margin-right: 16px;
}

.standard-layout__title-image-lg {
  max-width: 120px;
  max-height: 120px;
  margin-right: 16px;
}

.standard-layout__title-image-xl {
  max-width: 152px;
  max-height: 152px;
  width: 152px;
  margin-right: 16px;
}

@media only screen and (max-width: 1350px) {
  .layout-main {
    width: 100vw;
  }
}

@media only screen and (max-width: 1200px) {
  .layout-header-description {
    width: auto;
  }

  .layoutBreadcrumb {
    margin-top: 5px;
  }

  .userMenuPic {
    right: 0px;
    width: 46px;
    height: 50px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 800px) {
  .layoutTitle {
    margin-right: 75px;
  }

  .layoutBreadcrumb {
    display: none;
  }

  .layout-header-description {
    width: auto;
  }

  .layoutDesc {
    margin-left: 7px;
    width: 100%;
    height: auto;
  }

  .layout-header {
    margin-bottom: 25px;
  }

  .layoutMenus {
    width: 40px;
    height: 44px;
  }

  .layout-main {
    padding: 22px 19px 23px 22px;
  }
  .layout-main-w-payment-banner {
    padding: 60px 30px 39px 36px;
  }

  .store-menu-input input {
    margin-right: 20px;
  }

  .store-menu-input img {
    position: absolute;
    right: 20px;
    top: 18px;
    outline: none;
  }

  .standard-width-with-sidebar {
    padding-left: 22px !important;
    width: 100vw !important;
  }
  .standard-width-wo-sidebar {
    padding-left: 22px !important;
    width: 100vw !important;
  }
  .hamburger-menu-icon {
    display: inline-block;
    margin-right: 26px;
  }
  .store-menu-input {
    display: none;
  }

  .smallScreenMenu {
    background-color: #343434;
    border-radius: 3px;
    width: 100vw;
    max-height: 75vh;
    overflow-y: hidden;
    -webkit-padding-start: 0;
    margin-top: 25px;
    margin-left: -22px;
    position: absolute;
    z-index: 2 !important;
    display: block;
    overflow-x: hidden;
  }
  .userMenuList {
    display: none;
  }

  .standard-layout__back-btn,
  .standard-layout__title-image {
    display: none;
  }
}

.layout-actions {
  display: flex;
  gap: 8px;
}
