/* stylelint-disable selector-class-pattern */
.cb-integrations-settings .layout-header {
  width: 100%;
  max-width: 800px;
}

.cb-integrations-settings .layout-actions {
  align-items: center;
}

.cb-integrations-settings__body {
  max-width: 800px;
  font-weight: 500;
  text-align: left;
}

.cb-integrations-settings__title {
  margin: 12px 0;
  font-weight: 600;
  font-size: 16px;
}

.cb-integrations-settings__disabled {
  color: #979797;
}

.cb-integrations-settings__subtitle {
  margin: 12px 0 32px;
  color: #979797;
  font-weight: 400;
  font-size: 10px;
}
