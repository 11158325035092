.products-v2-drawer {
  .ant5-drawer-close {
    position: absolute;
    right: 0;
  }

  &__section {
    margin-bottom: 20px;

    &-title {
      font-weight: 600;
      font-size: 12px;
      color: #737373;
      text-transform: capitalize;
    }

    &-value {
      font-weight: 400;
      font-size: 16px;
    }
  }
}
