.product-details-content h1,
.product-details-content h2,
.product-details-content p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  color: #000000;
}

.product-details-content h1 {
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  margin: 0;
}

.product-details-content h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.product-details-content p {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 8px;
}

.product-details-content > section:first-child {
  margin-bottom: 22px;
}

.product-details-content > section:nth-child(2) {
  margin-bottom: 32px;
}

.product-details-content > section:last-child {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 32px;
}
