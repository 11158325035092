.videoModal {
  display: none;
  z-index: 10;
}

.videoModal.active {
  display: block;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 12;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.cover.active {
  display: flex;
}
