.store_closed_align {
  text-align: right !important;
  align-content: right;
}

/* |||| TIMEZONE DROPDOWN FOR STORES |||| */

.timezone-dropdown-edit-store .ant-select-arrow .anticon > svg {
  padding-bottom: 10px !important;
}

/* ||||||||||||||||||||||||||| */

@media only screen and (max-width: 1200px) {
  .store_closed_align {
    text-align: left !important;
    align-content: left;
  }
}
