.clients-v2-collection-product-carousel {
  display: flex;
}

.clients-v2-collection-product-carousel .ant-image img {
  border-radius: 6px;
}

.clients-v2-collection-product-carousel > div:first-child {
  padding-right: 12px;
}

.clients-v2-collection-product-carousel--selector {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
}
