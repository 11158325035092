.cb-payment-request {
  margin-left: 22px;
  margin-right: 22px;
  text-align: left;
  /* width: 100%; */
  max-width: 878px;
  margin-bottom: 50px;
}

.cb-payment-request--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 12px;
}

.cb-payment-request--header-title {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.cb-payment-request--view-all-button {
  color: #33AAFC;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 6px;
}

.cb-payment-request--view-all-button-empty {
  color: #33AAFC;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.cb-payment-request--body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.cb-payment-request--body-helper {
  font-size: 12px;
  font-weight: 400;
  color: #818181;
}
