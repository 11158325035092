.products-v2-card {
  display: flex;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #e6f4ff;
  }

  &__image {
    height: 40px;
    width: 40px;

    .ant5-image {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      & > img {
        border-radius: 4px;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 14px;
  }

  &__description {
    font-size: 12px;
    color: #737373;
  }
}
