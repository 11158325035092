.notif-cont {
  -webkit-transition: top 0.5s; /* Safari prior 6.1 */
  transition: top 0.5s;
  z-index: 10000;
  overflow-y: hidden;
  padding-left: 15px;
  height: 45px;
  left: calc(50vw - 150px);
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.notif-close {
  outline: none;
  height: 20px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
