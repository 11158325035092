.reports-sales-opportunity-details-modal {
  &--title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
  }

  &--close {
    width: 20px;
    height: 20px;
    color: #000;
  }

  .ant-modal-close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 30px;
    right: 38px;
  }

  .ant-modal-body {
    padding: 30px 40px;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &--header-title {
    font-weight: 500;
    font-size: 13px;
  }

  &--header-actions svg + span {
    margin-left: 8px;
  }

  &--body {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
    margin-bottom: 30px;
  }

  &--body-section {
    display: flex;
    flex-direction: column;
  }

  &--body-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #818181;
    margin-bottom: 4px;
  }

  &--body-content {
    font-weight: 500;
    font-size: 13px;
    color: #231F20;
  }

  &--body-stage {
    width: auto;
  }

  &--table {
    margin-bottom: 30px;
  }

  &--table-title {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
}
