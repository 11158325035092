.customActionBtn {
  font-size: 13px !important;
}

.custom-action-wrapper {
  max-width: 600px;
}

.checkBoxes {
  display: block;
  margin-bottom: 50px;
  float: left;
}

.checkBoxes div {
  margin-right: 147px;
}

.custom-action-save {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 56px;
}

.custom-action-save-flex {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.drop_down_div {
  display: flex;
  margin-top: 15px;
}

.drop_down_div div {
  margin-right: 20px;
}

@media only screen and (max-width: 1250px) {
  .checkBoxes div {
    margin-right: 75px;
  }
  .checkbox-text {
    text-align: left;
    width: 160px;
  }
}

@media only screen and (max-width: 800px) {
  .custom-action-wrapper {
    margin-left: -15px;
  }

  .checkBoxes {
    margin-left: 20px;
  }

  .custom-action-save {
    margin-left: 20px;
    justify-content: left;
    width: 95%;
  }
}
