.cd-viewall-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

}

.cd-viewall-header {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .standard-layout__back-btn {
      margin-right: 20px;
    }
  }
}

.cd-viewall-content {
  display: flex;
  padding: 0px 30px 30px;
  flex: 1;
}

.table-view-all-automessages {
  tr {
    border: 0;

    > td {
      padding: 6px 6px 8px 6px;
    }

    > th {
      padding: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }

  th {
    border: 0;
  }
}
