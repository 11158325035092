div.cb-select {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
}

span.cb-select__suffix {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #818181;
}

div.cb-select .ant-select {
  width: 100%;
  display: flex;
  align-items: center;
}

div.cb-select .ant-select .ant-select-selector {
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;
  padding: 0 16px 0 14px;
  border-radius: 4px;
  height: 48px;
}

div.cb-select__prefix .ant-select .ant-select-selector {
  padding: 0 16px 0 38px;
}

div.cb-select .ant-select .ant-select-selection-item .cb-select__suffix {
  display: none;
}

div.cb-select .ant-select .ant-select-selection-item,
div.cb-select .ant-select .ant-select-selection-search,
div.cb-select .ant-select .ant-select-selection-placeholder,
div.cb-select .ant-select .ant-select-selection-search-input {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  line-height: 48px;
  font-weight: 500;
  font-size: 13px;
  color: #000;
}

div.cb-select__prefix .ant-select .ant-select-selection-search {
  left: 38px;
}

div.cb-select .ant-select .ant-select-selection-placeholder {
  color: #818181;
}

div.cb-select .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
div.cb-select
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-right-width: 2px !important;
  box-shadow: none !important;
}

div.cb-select .ant-select-clear,
div.cb-select .ant-select-arrow {
  font-size: 16px;
  height: 16px;
  width: 16px;
  right: 16px;
  margin-top: -8px;
}

div.cb-select .ant-select-arrow .anticon-spin,
div.cb-select .ant-select-arrow .anticon-loading {
  color: #33aafc;
}

div.cb-select__prefix .ant-select-arrow .fa-angle-down,
div.cb-select__prefix .ant-select-arrow .anticon-search {
  display: none;
}


div.cb-select__dropdown {
  padding: 0;
}

div.cb-select__dropdown .ant-select-item-option {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #000;
}

div.cb-select__dropdown .ant-select-item-option-disabled {
  background-color: #f5f5f5;
  color: #999;
}

div.cb-select__dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e5f2fb;
}
