.cb-carousel {
  display: flex;
  gap: 6px;
  width: 100%;

  &__preview {
    flex: 1;

    .ant5-image {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      height: calc(100vh - 280px);
      max-height: 1000px;
      width: 100%;
      background-color: #fff;

      & > img {
        border-radius: 4px;
        height: calc(100vh - 282px);
        max-height: 1000px;
        object-fit: contain;
      }
    }
  }

  &__track {
    display: flex;
    gap: 6px;
    flex-direction: column;
    height: 500px;
    overflow-y: auto;

    .ant5-image {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      height: 75px;
      max-width: 75px;
      background-color: #fff;

      & > img {
        border-radius: 4px;
        height: 73px;
        width: 73px;
        object-fit: cover;
      }
    }
  }
}
