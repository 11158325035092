/* input sizes  */

.msgSettShortInput {
  width: 65px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  padding-left: 40px;
}
.msgSettTextarea {
  padding-top: 20px;
  padding-left: 18px;
  width: 848px;
  height: 100px;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
}
.msgSetSegmentTitle {
  text-align: left;
  margin-bottom: 32px;
}
.msgSetSegmentTitle img {
  margin-right: 14px;
  vertical-align: middle;
}
.msgSetSegmentTitle path {
  opacity: 1;
}
.msgSetSegmentTitle span {
  font-size: 14px;
  /* text-align: left; */
  font-family: Poppins;
  line-height: 27px;
  font-weight: 500;
  color: #000000;
}

.settingSave {
  display: flex;
  justify-content: center;
}

/* upload input button style area */

#redX {
  position: absolute;
  right: 30px;
  top: 18px;
  z-index: 10;
  display: none;
}

#redX_active {
  position: absolute;
  right: 30px;
  top: 18px;
  z-index: 10;
  display: block;
}

.upload_label {
  height: 35px;
  display: block;
  padding-top: 13px;
  z-index: 15;
}

.upload_label.active {
  opacity: 0.5;
}

.inputfile_red {
  display: none;
  z-index: 10;
}

#upload_div {
  width: 267px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #33aafc;
  position: relative;
}

#upload_div_active {
  width: 267px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  text-align: left;
  color: #000000;
  position: relative;
}

#upload_div_two {
  width: 267px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #33aafc;
  position: relative;
}

#upload_div_active_two {
  width: 267px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  text-align: left;
  color: #000000;
  position: relative;
}

/* media */

@media only screen and (max-width: 1200px) {
  .msgSettShortInput {
    float: left;
  }

  .msgSettTextarea {
    width: 500px;
    float: left;
    height: 100px;
  }
  .settingSave {
    float: left;
  }
}

@media only screen and (max-width: 800px) {
  .msgSetSegmentTitle {
    padding-left: 20px;
  }

  .msgSettShortInput {
    margin-left: 20px;
  }

  .msgSettTextarea {
    margin-left: 20px;
    width: 400px;
    padding-top: 10px;
    padding-left: 15px;
  }

  #upload_div {
    margin-left: 20px;
  }

  #upload_div_active {
    margin-left: 20px;
  }

  #upload_div_two {
    margin-left: 20px;
  }

  #upload_div_active_two {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .msgSettTextarea {
    width: 350px;
  }
}
