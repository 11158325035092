.thumbNail {
  width: 313px;
  height: 170px;
  border-radius: 3px;
  margin: -4px;
  object-fit: cover;
}

.mainVideoBody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.videoBtnImg {
  right: 145px;
  top: 60px;
}

.videoBtn {
  padding: 0;
  border: none;
  margin: 4px;
}

.videoData {
  height: 250px;
}

.videoTextHead {
  margin-bottom: 0px;
}

.videoTextSub {
  margin-bottom: 0px;
  margin-top: 0px;
}

.vidSectionHeader {
  text-align: left;
  margin-left: 10px;
  font-family: Poppins;
  font-size: 20px;
  color: #353538;
}

.fullscreen-video {
  width: auto;
  height: 100vh;
  max-height: 540px;
  max-width: 100%;
}

.exportDataList{
  text-align: left;
  font-size: 12px;
  color: #9c9c9c;
  width: 200px;
}

.exportDataListUL{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 145px;
  padding-left: 22px;
}

.exportAllButton{
  position: absolute;
  top: -55px;
  left: 665px;
}

@media only screen and (max-width: 1200px) {
  .contactSecondDiv {
    margin-top: 10px;
  }
}
