.colorSelect {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.defaultImageDiv {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  position: relative;
}
.defaultImage {
  position: absolute;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 50%;
}

.zIndexForWebchat {
  z-index: 0;
}

.previewDiv {
  background-color: #f2f2f2;
  width: 400px;
  height: 500px;
  border-radius: 12px;
  margin-top: 122px;
  margin-left: 50px;
}

.previewDiv2 {
  background-color: #f2f2f2;
  width: 450px;
  height: 725px;
  border-radius: 12px;
  margin-top: 65px;
  margin-left: 50px;
}

/* webchat preview styles */

#wlbkwc_messageButton {
  position: absolute;
  right: 25px;
  margin-right: 15px;
  box-shadow: 0 12px 19px 0 rgb(0 0 0 / 18%);
  width: 68px;
  height: 68px;
  border-radius: 50%;
  z-index: 5;
  display: block;
}

.clbkwc_btn_class {
  background-image: url(https://webchat.clientbook.com/images/ic-message.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: none !important;
  width: 68px !important;
  height: 68px !important;
  border-radius: 50% !important;
  transition: 0.25s !important;
  display: inline-block !important;
  margin: 0 !important;
}

#clbkwc_chat_id {
  position: absolute;
  top: 175px;
  right: 105px;
  width: 297px;
  height: 79px;
  z-index: 5;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0 4px 24px -4px rgb(0 0 0 / 18%);
  margin-right: -65px;
  padding: 0;
  border: 0;
}

#wlbkwc_avatar_img {
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: block;
  margin-top: 20px;
  margin-left: 20px;
  border: 0;
  vertical-align: middle;
}

#wlbkwc_chat {
  top: 5px;
  left: 72px;
  width: 215px;
  height: 55px;
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 20px;
  color: #000;
  margin: 0;
  border: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  text-align: left;
}

#wlbkwc_chat_carrot {
  display: block;
  margin-left: 252px;
  margin-top: 75px;
  position: absolute;
}

#wlbkwc_chatExit {
  display: block;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  height: 15px;
  width: 15px;
}

/* store widget view */

#widget-outer {
  overflow-y: hidden;
  background-color: #fff;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 0 13px 27px #0000002e;
  color: #333;
  float: right;
  padding: 0;
  width: 402px;
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 94px;
  right: 25px;
}

.storeSelectDiv {
  background-color: #000;
  color: #fff;
  height: 94px;
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 50px;
}

.storeSelectText {
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}

.whiteArrow {
  position: absolute;
  left: 5px;
  padding: 10px;
  top: 30px;
}

.selectLocationSubText {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.messageUpper {
  animation: leftMessage 0.5s ease forwards 0s;
  transform-origin: bottom left;
  transform: scale(1);
  z-index: 0;
}
.widg-head {
  margin: 14px 47px 11px 60px;
}
.head-chat {
  font-family: Poppins, sans-serif;
  border-radius: 23px;
  background-color: #33aafc;
  color: #fff;
  padding: 10px 21px 10px 25px;
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
}
.message {
  display: flex;
  margin-top: -9px;
}

.chatImg {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: block;
  margin-top: 25px;
  margin-left: 13px;
  object-fit: cover;
}
.widg-head-2 {
  margin: -9px 47px 0 7px;
}

.carrotStyle {
  margin-top: -22px;
  margin-left: -6px;
  margin-bottom: 0;
  z-index: 10;
  display: block;
  width: 25px;
  height: 25px;
}
.carrot {
  fill: #33aafc;
}
.form_all {
  background-color: #fff;
  margin-top: 15px;
}
.form {
  width: 279px;
  height: 180px;
  border-radius: 23px;
  background-color: #eaeaea;
  margin-left: 93px;
}
#form_input,
#form_input_top,
#form_input_bot,
#form_input2 {
  width: 227px;
  height: 34px;
  border-radius: 4px;
  background-color: #fff;
  border-style: none;
  font-size: 14px;
  color: #000;
  padding-left: 16px;
  font-family: Poppins;
}
#form_input_top {
  margin: 23px 23px 10px 18px;
}
#form_input,
#form_input2 {
  margin: 3px 23px 10px 18px;
}
#form_input_bot {
  margin: 3px 23px 23px 18px;
}
.agree_text {
  font-family: Poppins, sans-serif;
  width: 266px;
  height: 30px;
  font-size: 9px;
  line-height: 1.67;
  text-align: center;
  color: #cdcbcb;
  margin: 15px 60px 13px;
}
.submit_but {
  width: 122px;
  height: 44px;
  border-radius: 22px;
  background-color: #c8c8c8;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0 132px 7px;
  border: none;
  outline: none;
}
.greyCarrot {
  margin-top: -22px;
  margin-left: 350px;
  display: block;
  width: 32px;
  height: 22px;
}
.footerContainer {
  width: 100%;
  position: sticky;
  bottom: 0;
}

.widg-foot {
  width: 100%;
  height: 45px;
  background-color: #f5f5f5;
  font-size: 9px;
  font-weight: 500px;
  color: #5c5858;
  margin-top: 12px;
  position: relative;
}

.widg-foot-logo-div {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #9c9c9c;
  border-radius: 3px;
  top: 15px;
  left: 125px;
}

.powered_by {
  font-family: Poppins, sans-serif;
  padding-left: 154px;
  padding-top: 18px;
  width: 110px;
}

.footerImg {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 3px;
  left: 3px;
}

/* blacklisted */

.blackListedDiv {
  height: 75px;
  /* margin-top: 25px; */
  /* border-top: 1px solid #bdbdbd; */
  text-align: left;
}
.blackListedDiv:hover {
  background-color: #f1f3f4;
}

.blackListedPagesDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.borderBottom {
  border-bottom: 1px solid #bdbdbd;
  min-height: 75px;
}

.googleAnalyticsDiv{
 height: 75px;
  margin-top: 25px;
  border-top: 1px solid #bdbdbd;
  text-align: left;
}
.googleAnalyticsDiv:hover {
  background-color: #f1f3f4;
}

.copyCode {
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  margin-top: 15px;
}
