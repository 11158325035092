.side-panel {
  position: fixed;
  left: 0px;
  width: 79px;
  height: 100vh;
  background-color: #33aafc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
  overflow-x: hidden;
  /* z-index: 10; */
}

.side-panel:hover {
  width: 79px;
  overflow-x: visible;
}

.sp-button-container {
  height: 52px;
  width: 79px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.sp-button {
  z-index: 10;
  font-size: 12px;
  font-weight: 500;
  color: #45484b;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

/* .sp-button-container:hover {
  background-color: #007bd0;
  opacity: 1;
  z-index: 20 !important;
}

.sp-button-container:active {
  background-color: #007bd0;
  opacity: 1;
  z-index: 20 !important;
} */

.menuCarrot {
  position: absolute;
  right: 182px;
  width: 24px;
  height: 21px;
  /* visibility: hidden; */
  margin-top: 25px;
  z-index: 30;
}

.menuCarrotMv {
  position: absolute;
  right: 190px;
  width: 24px;
  height: 21px;
  /* visibility: hidden; */
  margin-top: 25px;
  z-index: 30;
}

#menuCarrotSecondaryAdmin {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: -54px;
  z-index: 105;
}

#menuCarrotMessagingAdmin {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 92px;
  z-index: 105;
}

#menuCarrotMessagingAdminWA {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 45px;
  z-index: 105;
}

#menuCarrotMessagingManager {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 95px;
  z-index: 105;
}

#menuCarrotMessagingManagerWA {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 47px;
  z-index: 105;
}

#menuCarrotMessagingAssociate {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 50px;
  z-index: 105;
}

#menuCarrotMessagingAssociateWA {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 2px;
  z-index: 105;
}

#menuCarrotHelpAdmin {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 352px;
  z-index: 105;
}

#menuCarrotHelpManager {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 330px;
  z-index: 105;
}

#menuCarrotHelpAssociate {
  position: absolute;
  right: 194px;
  width: 24px;
  height: 21px;
  margin-top: 120px;
  z-index: 105;
}

.sp-button div:nth-child(odd) {
  width: 80px;
}

.sp-button span {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.sp-button div:nth-child(odd) svg {
  width: 25px;
  height: 50px;
  margin-bottom: -15px;
  padding: 3px 28px 7px 28px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
}
.sp-button div:nth-child(odd) img {
  /* width: 25px;
  height: 70px;
  margin-bottom: -20px;
  padding: 3px 28px 3px 28px;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  margin-top: -10px; */
  width: 25px;
  height: 50px;
  margin-bottom: -15px;
  /* padding: 3px 28px 7px 28px; */
  text-align: left;
  display: table-cell;
  vertical-align: middle;
}

#showListStyle {
  width: '269px' !important;
  overflow-x: 'none';
}

#showList_noExpansionStyle {
  width: '79px';
  overflow-x: 'visible';
}

/* naomi's styles here */

.vertNavDiv {
  width: 152px;
  height: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #f2f3f6;
  margin: 0px 36px 11px 13px;
}
.vertNavInnerDiv {
  width: 50px;
  height: 27px;
  position: relative;
  margin-left: 20px;
}
.vertNavInnerDiv:hover {
  border-bottom: solid 2px #33aafc;
  animation-name: borderGrow;
  animation-duration: 0.6s;
}

@keyframes borderGrow {
  0% {
    width: 20px;
  }
  100% {
    width: 50px;
  }
}

/* mobile */

/* .mobileUL:hover {
  background-color: #007bd0;
  opacity: 1;
  z-index: 20 !important;
} */

.sp-button-mobile {
  font-size: 12px;
  font-weight: 500;
}

.expanded-nav {
  height: 100vh;
  width: 200px;
  position: absolute;
  z-index: 50;
  top: 0;
  background-color: var(--cbGrey);
  padding-top: 16px;
  display: block;
  opacity: 1;
}

.expanded-nav ul {
  margin-bottom: 0px;
}

.expanded-nav .mobile-scroll {
  margin-top: 0;
}

.expanded-nav-item {
  color: #45484b;
}

.expanded-nav li {
  font-size: 13px;
  font-weight: 500;
  opacity: 1;
  text-align: left;
  line-height: 4;
  list-style: none;
  /* padding: 0px 0px 0px 25px; */
  margin-left: -40px;
  height: 48px;
  width: 175px;
}
.expandHeader {
  text-align: left;
  letter-spacing: 0.6px;
  font-weight: 600;
  color: #343434;
  font-size: 14px;
  margin-left: -15px;
  text-transform: uppercase;
}

.expandHR {
  margin-left: -15px;
  width: 115px;
  border: solid 1px #343434;
  margin-bottom: 20px;
}

.expandHeaderMobile {
  width: 66px;
  height: 16px;
  margin: 4px 0 34px 20px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495155;
  text-align: left;
}

.mobileSideNav {
  display: none;
}

.mobileSideNav {
  display: block;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  border: none;
  margin: 14px 0px 12px 31px;
}

.secondary-panel {
  height: 100vh;
  width: 200px;
  position: fixed;
  z-index: 0;
  top: 50px;
  left: 0;
  background-color: #f2f3f6;
  padding-top: 16px;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.secondary-panel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.secondary-panel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.secondary-panel ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
}

.secondary-panel li {
  font-size: 13px;
  opacity: 1;
  font-weight: 500;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  list-style: none;
}

.SPlink_Active {
  width: 146px;
  height: 27px;
  border-radius: 3px;
  background-color: #bcd5e7;
}

/* z-index areas */
.nav-z-100 {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.16);
}

.nav-z-101 {
  z-index: 101;
}

.nav-z-102 {
  z-index: 102;
}

/* MOBILE SPECIFIC CLASSES */

.mobile-side-panel {
  display: none;
  z-index: 5;
  padding: 19px 19px 23px 22px;
  width: 257px;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #f2f3f6;
}

.mobile-side-panel ul {
  margin-top: 50px;
  height: calc(100vh - 82px);
  overflow: scroll;
  padding-left: 0px;
}

.mobile-side-panel ul li {
  list-style: none;
}

/* naomi adding styles 06/01/2021 */
.mobile-sub-nav-panel {
  display: none;
  z-index: 40;
  padding: 19px 0px 23px 22px;
  width: 257px;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #f2f3f6;
  animation-name: translateNavX;
  animation-duration: 0.5s;
}

.mobile-sub-nav-panel-banner {
  display: none;
  z-index: 40;
  padding: 65px 0px 23px 22px;
  width: 257px;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #f2f3f6;
  animation-name: translateNavX;
  animation-duration: 0.5s;
}

@keyframes translateNavX {
  0% {
    transform: translateX(-18em);
  }
  100% {
    transform: translateX(0em);
  }
}

.mobile-sub-nav-panel ul {
  margin-top: 0;
  height: calc(100vh - 82px);
  padding-left: 0px;
}

.expanded_mobile_sub_nav {
  text-decoration: none;
  width: 54px;
  height: 17px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #45484b;
}

.subMenuHover {
  width: 146px;
  height: 27px;
  border-radius: 3px;
}
.subMenuHover:hover {
  background-color: #bcd5e7;
}

.vertNavDivMobile {
  width: 250px;
  height: 27px;
  position: relative;
  border-radius: 3px;
  background-color: #f2f3f6;
  margin: 0px 0px 22px 0px;
}
.vertNavDivMobile:hover {
  background-color: #bcd5e7;
}

.subMenu_toggle {
  animation-name: translateToggle;
  animation-duration: 0.5s;
}

@keyframes translateToggle {
  0% {
    transform: translateX(0em);
  }
  100% {
    transform: translateX(-18em);
    display: none;
  }
}

/* multi nav */

.multiNavOutterDiv {
  display: none;
}
.multiNavOutterDivBanner {
  display: none;
}

.mobile-multi-nav {
  display: none;
  z-index: 40;
  padding: 19px 0px 23px 22px;
  width: 257px;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #f2f3f6;
  animation-name: translateMultiNavX;
  animation-duration: 0.5s;
}

@keyframes translateMultiNavX {
  0% {
    transform: translateX(-18em);
  }
  100% {
    transform: translateX(0em);
  }
}

.multi-mobile-side-pane-x {
  position: absolute;
  right: 22px;
  top: 22px;
  color: #45484b;
}
.multi-mobile-side-pane-x-banner {
  position: absolute;
  right: 22px;
  top: 70px;
  color: #45484b;
}

/* end */

.mobile-side-pane-x {
  position: absolute;
  right: 22px;
  top: 22px;
  color: #45484b;
}
.mobile-side-pane-x-banner {
  position: absolute;
  right: 22px;
  top: 70px;
  color: #45484b;
}
.mobile-side-panel-bg-closer {
  display: none;
  background-color: transparent;
  z-index: 4;
  opacity: 100%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 269px);
}
.mobile-side-panel-bg-closer-multi {
  display: none;
  background-color: transparent;
  z-index: 4;
  opacity: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 269px);
}

@media only screen and (max-width: 800px) {
  .multiNavOutterDiv {
    display: block;
    z-index: 5;
    padding: 19px 19px 23px 22px;
    width: 257px;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: #f2f3f6;
    overflow-x: scroll;
  }
  .multiNavOutterDivBanner {
    display: block;
    z-index: 5;
    padding: 70px 19px 23px 22px;
    width: 257px;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: #f2f3f6;
  }
  .nav2Container {
    display: none;
  }
  .secondary-panel {
    display: none;
  }
  .mobile-side-panel {
    display: inline-block;
  }
  .sp-button div {
    vertical-align: middle;
  }
  .sp-button path {
    height: 100%;
    vertical-align: middle;
  }
  .sp-button img {
    height: 50%;
    vertical-align: middle;
  }
  .mobile-side-panel-bg-closer {
    display: inline-block;
  }
  .mobile-side-panel-bg-closer-multi {
    display: inline-block;
  }
  /* .sp-button-container:hover {
    background-color: #007bd0;
    opacity: 1;
  }

  .sp-button-container:active {
    background-color: #007bd0;
    opacity: 1;
  } */

  /* .expanded-nav {
    left: 70px;
  } */

  .expanded-nav li {
    margin-left: 0px;
  }
}

@media only screen and (max-height: 700px) {
  .hide-carrot-short {
    display: none;
  }

  .nav-bar-short {
    padding: 0;
    margin: 0;
    width: inherit;
    padding-bottom: 10px;
    margin-bottom: 10px;
    overflow: scroll;
  }

  /* .secondary-menu-short {
    overflow: scroll;
    height: calc(100vh - 95px);
  } */
}
