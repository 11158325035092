.smallTableWidth {
  width: 356;
}

@media only screen and (max-width: 1000px) {
  .smallTableWidth {
    min-width: 356px;
    max-width: 860px;
    width: 100%;
  }
}
