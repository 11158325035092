.cb-activity-item-container {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.11);
  padding: 16px 28px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:hover .cb-activity-item--edit-button {
    display: inline-block;
  }

  > .cb-activity-strip {
    width: 11px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  > .flex-row-spacebetween-start {
    gap: 16px;

  }

  > .show-effect {
    animation: fadeIn 600ms;
  }

  > .hide-effect {
    animation: fadeOut 300ms;
  }

  > .hide {
    height: 0;
    overflow: hidden;
    margin-top: 0px;
  }

}

.cb-activity-item-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > span {
    color: #9C9C9C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  > svg {
    margin: 0 0 2px 8px;
  }
}

.cb-activity-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  text-align: left;

  p {
    margin: 0 0 5px;
  }

  > div {
    color: #9C9C9C;
  }
}

.ant-dropdown-menu {
  border-radius: 12px;
  max-width: 200px;
  max-height: 35vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #F2F3F6;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #EDEEF1;
  }

  &::-webkit-scrollbar-track:active {
    background-color: #EAEBEE;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #BDBDBD;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #B8B8B8;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #A8A8A8;
  }

  .ant-dropdown-menu-item {

    border-bottom: 0.5px solid rgba(129, 129, 129, 0.5);

    &:last-child {
      border-bottom: none;
    }

    & > span {
      padding: 4px 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #000;
    }
  }

}

.cd-dropdown-menu-filter {

  padding: 16px 22px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-height: 320px;
  width: 200px;

  .cd-dropdown-menu-filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > p {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    > span {
      color: #1769AF;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .ant-dropdown-menu {

    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #F2F3F6;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #EDEEF1;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #EAEBEE;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #BDBDBD;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #B8B8B8;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: #A8A8A8;
    }

    > .ant-dropdown-menu-item {
      padding: 0;
      border: 0 !important;
      margin: 2px 0;

      > span {
        padding: 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.cb-activity-item--edit-button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #33AAFC;
  margin-left: 4px;
  cursor: pointer;
  display: none;
}

@keyframes fadeIn {
  from {
    transform: translateY(-10%);
    opacity: 0;
    height: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
    height: 152px;
  }
}

@keyframes fadeOut {

  from {
    transform: translateY(0%);
    opacity: 1;
    height: 152px;
  }

  to {
    transform: translateY(-10%);
    opacity: 0;
    height: 0;
  }
}

.cb-activity-purchase-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  padding: 0;
  height: 152px;
  margin-top: 16px;

  > .flex-col-left > p {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-block-start: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 12px;
  }

  .cb-activity-purchase-details-grade {
    display: grid;
    grid-template-columns: 60px 100%;
    gap: 4px 4px;
    grid-template-areas: "label value";

    .cb-activity-purchase-details-label {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      grid-area: "label";
    }

    .cb-activity-purchase-details-value {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      grid-area: "value";
    }
  }
}
