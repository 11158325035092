div.cb-products-list-table-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

div.cb-products-list-table-item-padding-and-border-radius {
  padding: 8px 14px;
  border-radius: 4px;
}

div.cb-products-list-table-item.clickable {
  cursor: pointer;
}

div.cb-products-list-table-item:hover {
  background-color: #f5f6f8;
}

div.cb-products-list-table-item.reverse-hover-color:hover {
  background-color: white;
}

span.cb-products-list-table-item__image {
  background-color: #e9e9e9;
  border-radius: 4px;
}

p.blue-link.hide-unless-hovered {
  display: none;
}

div.cb-products-list-table-item.reverse-hover-color:hover p.blue-link.hide-unless-hovered {
  display: block;
}

h4.cb-products-list-table-item__title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
}

p.cb-products-list-table-item__description {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

div.cb-products-list-table-item__tag {
  flex: 1;
  display: flex;
  justify-content: right;
}

div.cb-products-list-table-item__tag span {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ff7a7a;
  border-radius: 4px;
  padding: 4px 6px;
}


.cb-products-list-table-item__checkbox span.ant-checkbox-inner {
  border-radius: 50%;
}

.cb-products-list-table-item__checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.cb-products-list-table-item__checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}


.cb-products-list-table-item:hover {
  background-color: #F2F3F6 !important;
}
