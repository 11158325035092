.sortButtonDiv {
  /* display: inline-block; */
  cursor: pointer;
  width: 12px;
  margin-right: 0px !important;
  margin-left: 15px;
  float: right;
}

.sortButtonDiv .upBtn {
  /* margin-top: 1px; */
  height: 6px;
  border: none;
  background-repeat: no-repeat;
  position: absolute;
  outline: none;
  cursor: pointer;
}

.sortButtonDiv .downBtn {
  margin-bottom: -2px;
  height: 6px;
  border: none;
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
}
