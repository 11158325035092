/* stylelint-disable selector-class-pattern */
.cb-integrations-details .layout-header {
  width: 100%;
  max-width: 800px;
}

.cb-integrations-details .layout-actions {
  align-items: center;
}

.cb-integrations-details__helper {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 32px;
  color: #33aafc;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cb-integrations-details__header {
  margin-bottom: 32px;
  border-radius: 6px;
}

.cb-integrations-details__body {
  max-width: 800px;
  font-weight: 500;
  text-align: left;
}

.cb-integrations-details__title {
  font-weight: 600;
}

.cb-integrations-details__section {
  margin-bottom: 32px;
}

.cb-integrations-details__list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.cb-integrations-details__list li {
  margin-bottom: 16px;
  padding-left: 1.2em;
  text-indent: -1.2em;
}

.cb-integrations-details__list li::before {
  content: '\2022';
  padding-right: 5px;
}

.cb-integrations-details__form-footer {
  margin-bottom: 0 !important;
}

.cb-integrations-details__form-footer .ant-form-item-control-input-content {
  display: flex;
  gap: 16px;
}

.cb-integrations-details-google__p {
  margin: 0;
  color: #979797;
  font-weight: 400;
}

.cb-integrations-details-google__p-unsynced {
  margin: 0;
  color: #BDBDBD;
  font-weight: 400;
}

.cb-integrations-details__title-google-unsynced {
  font-weight: 600;
  color: #BDBDBD;
}

.cb-integrations-details-google__container {
  border-radius: 8px;
  background:#F2F3F6;
  padding: 32px;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.cb-integrations-details-google__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
