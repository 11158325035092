.lineSpan::before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 42%;
  border-bottom: 1px solid #dadada;
  margin-right: 30px;
}

.lineSpan::after {
  content: '';
  display: inline-block;
  width: 42%;
  height: 100%;
  border-bottom: 1px solid #dadada;
  margin-left: 30px;
}

.inboxHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  max-width: 1100px;
}

.storeInboxHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  max-width: 1100px;
}

.searchAndRefresh {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.inboxSearch {
  /* width: 100%; */
  margin-left: 15px;
  height: 50px;
  width: 382px;
}

.myInboxSearch {
  height: 50px;
  width: 382px;
}

.inboxFilter {
  width: 267px;
}

.desktopRefresh {
  right: 10px;
  z-index: 100;
  top: 18px;
  position: relative;
}

.clientAssign {
  top: 100px;
}

.newMessageButton {
  background-color: #1769af;
  color: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  height: 50px;
  line-height: 20px;
  font-weight: 600;
  outline: none;
  width: 175px;
  border-radius: 3px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.whatsAppHover:hover {
  background-color: #e8f6ff;
}
.whatsAppHover {
  background-color: #f3f3f3;
  max-height: 88px;
}

#imageToolRow {
  display: flex;
}

#imageToolRowHidden {
  display: none;
}

@media only screen and (max-width: 1024px) {
  #imageToolRowHidden {
    display: flex;
  }
}

#highlightSelected {
  background-color: #e8f6ff;
}
#bgOffwhite {
  background-color: #f4f4f4;
}

#msgOnlyHover {
  background-color: #f3f3f3;
}

/* .msgOnlyHover:hover {
  background-color: #f3f3f3;
} */

.chatLineTrash {
  visibility: hidden;
}
.chatLine:hover .chatLineChild .chatLineTrash {
  visibility: visible;
}
.chatLine:hover .chatLineTrash {
  visibility: visible;
}

.chatLine .chatLineChild {
  visibility: hidden;
}
.chatLineChild {
  visibility: hidden;
}
.chatLine:hover .chatLineChild {
  visibility: visible;
}
#msgOnly {
  background-color: #ffffff;
}

.whatsAppTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
  max-height: 88px;
}

.placeholderInput {
  width: 308px;
  padding-left: 10px;
}

.onHoverImg:hover {
  opacity: 0.8;
}

.allTemplateSearch {
  height: 49px;
  width: 568px;
}

.pop-confirm-container-large-messaging {
  z-index: 11;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 20vh;
  /* left: 20vw; */
  /* margin: 0 0 0 10vw; */
  left: 50%;
  transform: translateX(-50%);
}

/* mobile styles for chat messages */
.dashboard_msg_time_margin {
  margin-right: 60px;
}
.dashboard_msg_garbage_margin {
  margin-right: 120px;
}
.inboxMobileTextContainer {
  max-width: 1000px;
}

.dashboard_msg_img_popup {
  width: 500px;
  height: 500px;
}
.dashboard_msg_img {
  margin-left: 60px;
}

/* |||| TIMEZONE DROPDOWN |||| */
.timezone-dropdown .ant-select-selector {
  text-align: left;
  background-color: none;
  border: solid 1px #818181 !important;
  border-radius: 3px !important;
  height: 48px !important;
  outline: none !important;
  font-size: 0.85em !important;
}

.timezone-dropdown .ant-select-selection-item {
  margin-left: 7px;
  margin-top: 5px;
  padding: 5px;
}

.timezone-dropdown .ant-select-arrow {
  margin-top: 0px;
  position: absolute;
  left: 98% !important;
}
/* ||||||||||||||||||||||||||| */

@media only screen and (max-width: 1465px) {
  .lineSpan::before {
    width: 40%;
  }

  .lineSpan::after {
    width: 40%;
  }
}

@media only screen and (max-width: 1240px) {
  .lineSpan::before {
    width: 38%;
  }

  .lineSpan::after {
    width: 38%;
  }
  .inboxMobileTextContainer {
    max-width: 700px;
  }
}

@media only screen and (max-width: 1100px) {
  .inboxHeader {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: stretch;
    align-content: space-around;
    justify-content: space-around;
    width: 100%;
  }
  .storeInboxHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .searchAndRefresh {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: space-around;
  }
  .inboxSearch {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  .myInboxSearch {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  .inboxFilter {
    width: 100%;
  }

  .desktopRefresh {
    top: 28px;
    margin-right: -15px;
  }

  .clientAssign {
    top: 130px;
  }
  .lineSpan::before {
    width: 20%;
  }

  .lineSpan::after {
    width: 20%;
  }

  .whatsAppTemplate {
    flex-direction: column;
    max-height: fit-content;
  }

  .whatsAppHover {
    max-height: fit-content;
    margin-bottom: 5px;
  }

  .newMessage {
    text-align: left;
    align-content: left;
    margin-top: 10px;
  }
  .newMessageButton {
    width: 100%;
  }

  .allTemplateSearch {
    width: calc(100% - 55px);
  }
  .inboxMobileTextContainer {
    max-width: 500px;
  }
}

@media only screen and (max-width: 950px) {
  .inboxMobileTextContainer {
    max-width: 400px;
  }
}

@media only screen and (max-width: 800px) {
  .pop-confirm-container-large-messaging {
    top: 20vh;
    left: 15vw;
    margin: 0 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .pop-confirm-container-large-messaging {
    top: 20vh;
    left: 20vw;
    margin: 0 0 0 0;
  }

  .inboxMobileTextContainer {
    width: 210px;
  }

  .dashboard_msg_time_margin {
    margin-right: 0px;
  }
  .dashboard_msg_garbage_margin {
    margin-right: 50px;
  }
  .dashboard_msg_img_popup {
    width: 300px;
    height: 300px;
  }
  .dashboard_msg_img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .pop-confirm-container-large-messaging {
    top: 20vh;
    left: 8vw;
    margin: 0 0 0 0;
  }
}
