/* stylelint-disable selector-class-pattern */
.cb-integrations-cards {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.cb-integrations-cards::-webkit-scrollbar {
  height: 20px;
}

.cb-integrations-cards::-webkit-scrollbar-track {
  background-color: transparent;
}

.cb-integrations-cards::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  border-radius: 20px;
  background-color: #d6dee1;
  background-clip: content-box;
}

.cb-integrations-cards::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
