button.cb-checklist-dropdown__button:focus,
button.cb-checklist-dropdown__button:hover,
button.cb-checklist-dropdown__button {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  height: 48px;
  border-radius: 4px;
  padding: 14px;
  color: #BDBDBD;
  background-color: #FFF;
  border: 1px solid #818181;
  margin-left: 14px;
  display: flex;
  align-items: center;
}

button.cb-checklist-dropdown__button.ant-popover-open {
  color: #000;
  border: 2px solid #33aafc;
}
