/* stylelint-disable selector-class-pattern */
.cb-integrations-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 288px;
  height: 180px;
  padding: 32px 24px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  background-color: #fff;
  scroll-snap-align: start;
}

.cb-integrations-card:hover {
  background-color: #f2f3f6;
  /* stylelint-disable-next-line */
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cb-integrations-card__header {
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;
  justify-content: center;
  color: #000;
}

.cb-integrations-card__footer {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  color: #1769af;
  font-weight: 600;
  line-height: 1;
}

.cb-integrations-card__footer span {
  margin-right: 8px;
}

.cb-integrations-card__image img {
  width: 100%;
  max-width: 48px;
  height: 100%;
  max-height: 48px;
}

.cb-integrations-card__image-lg img {
  width: 100%;
  max-width: 120px;
  height: 100%;
  max-height: 60px;
}

.cb-integrations-card__title {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}
