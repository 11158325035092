div.cb-products-page__table-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

div.cb-products-page__table-filters,
div.cb-products-page__table-actions {
  display: flex;
}
