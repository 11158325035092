.reminderCard {
    min-width: 409px;
    width: 100%;
    min-height: 120px;
    max-height: 140px;
    background: #ffffff;
    box-shadow: 1px 2px 9px rgb(0 0 0 / 11%);
    border-radius: 6px;
    position: relative;
}

.reminderStrip {
    height: 100%;
    position: absolute;
    border-radius: 6px 0px 0px 6px;
    width: 11px;
}

.reminderCardPadding {
    padding: 10px 20px 0px;
}

.reminderBottomStrip {
    border-top: 1px solid rgb(243, 243, 243);
    bottom: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: calc(100% - 32px);
}

.table-container-reminder{
    height: auto;
        overflow-y: scroll;
        width: 100%;
        max-width: 100%;
}
.table-container-reminder-class{
    height: auto;
    padding-bottom: 145px;
}
