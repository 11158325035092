.cb-edison-ai-client-details {
  font-weight: 500;
  font-size: 14px;
  padding:  16px 32px;
  width: 480px;
  max-width: 480px;
  text-align: left;

  &__hr {
    background-color: #d9d9d9;
    height: 1px;
    border: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 12px;
  }

  &__grey-text {
    font-weight: 400;
    font-size: 12px;
    color: #737373;
  }

  &__chat-message-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 80%;
  }

  &__chat-message-inbound {
    display: flex;
    justify-content: left;

    .cb-edison-ai-client-details__chat-message-container {
      align-items: flex-start;
    }

    .cb-edison-ai-client-details__chat-message-body {
      background-color: #e8e8e8;
      border-radius: 8px;
      padding: 8px;
      font-weight: 400;
      font-size: 12px;
      color: #000;
      width: fit-content;
    }
  }

  &__chat-message-outbound {
    display: flex;
    justify-content: right;
    text-align: right;

    .cb-edison-ai-client-details__chat-message-container {
      align-items: flex-end;
    }

    .cb-edison-ai-client-details__chat-message-body {
      background-color: #e0f2ff;
      border-radius: 8px;
      padding: 8px;
      font-weight: 400;
      font-size: 12px;
      color: #000;
      width: fit-content;
    }
  }

  &__chat-message-header {
    font-weight: 400;
    font-size: 10px;
    color: #737373;
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}
