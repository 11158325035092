.container {
  margin-top: 52px;
}

.container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  width: 100%;
  height: 295px;
  /* border: 1px dashed #818181; */
  border-radius: 3px;
  margin-top: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}