.cb-all-client-payment-requests {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--container {
    width: 100%;
    max-width: 878px;
  }

  &--dropdown {
    &-content {
      padding: 20px 30px;
      background: #fff;
      box-shadow: 0px 2px 8px 0px #00000026;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-checkbox,
    .ant-checkbox-inner,
    .ant-dropdown-menu-title-content {
      padding: 0 !important;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }

    .ant-dropdown-menu {
      max-width: 400px !important;
      display: flex;
      flex-direction: column;
      justify-content: left;
      gap: 10px;

      .ant-dropdown-menu-item {
        border-bottom: none;
        padding: 0;;

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;

          & > span {
            padding: 14px 10px 8px 10px;
          }
        }

        & > span {
          font-size: 12px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &--footer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &--filter-title {
    font-size: 16px;
    font-weight: 600;
  }

  &--filter-clear {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #1769AF;
  }


  &--filter-apply {
    background: #39A2FC;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 30px;
    height: auto;

  }

  &--items {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    text-align: left;
  }

  &--pagination {
    display: flex;
    justify-content: center;
    margin: 8px 0;
  }

  &--subtitle {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #818181;
  }
}
