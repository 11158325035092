.ant-picker-input>input{
    font-size: 12px;
}

.relationshipNewInput{
    width: 100%;
    height: 30px;
    margin-top: 15px;
    border: none;
    border-bottom: 1px #818181 solid;
    border-radius: 0px;
}

.relationshipNewDiv{
    display: flex;
    position: relative;
    width: 275px;
}