.cb-product-details-carousel__empty {
  background-color: #e9e9e9;
  border-radius: 4px;
  height: 600px;
}

.cb-product-details-carousel__container {
  position: relative;
}

.product-details-layout__container {
  display: flex !important;
}

div.cb-product-details-carousel__tag {
  position: absolute;
  z-index: 10;
  right: 16px;
  top: 16px;
  margin-right: 8%;
}

div.cb-product-details-carousel__tag span {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ff7a7a;
  border-radius: 4px;
  padding: 4px 6px;
  margin: 0;
}

.cb-product-details-carousel__no-image {
margin-left: 10px;
}

.cb-product-details-carousel .ant-carousel .slick-list {
  border-radius: 4px;
}

.cb-product-details-carousel .ant-carousel .slick-prev::before,
.cb-product-details-carousel .ant-carousel .slick-next::before {
  content: '';
}

.cb-product-details-carousel .ant-carousel .slick-prev,
.cb-product-details-carousel .ant-carousel .slick-next {
  background-color: rgba(0, 0, 0, 0.75);
}

.cb-product-details-carousel .ant-carousel .slick-prev {
  left: 16px;
}

.cb-product-details-carousel .ant-carousel .slick-next {
  right: 16px;
}

.cb-product-details-carousel .ant-carousel .slick-prev,
.cb-product-details-carousel .ant-carousel .slick-prev:hover,
.cb-product-details-carousel .ant-carousel .slick-next,
.cb-product-details-carousel .ant-carousel .slick-next:hover {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  padding: 12px;
  color: #fff;
  z-index: 1;
}

.cb-product-details-carousel .ant-carousel .slick-prev:hover,
.cb-product-details-carousel .ant-carousel .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

.cb-product-details-carousel {
  max-width: 550px;
}

.cb-product-details-carousel .ant-carousel .ant-image img {
  object-fit: cover;
}

.cb-product-details-carousel__slide-selector {
  padding: 16px 0;
  display: flex;
  gap: 16px;
  overflow-y: auto;
}

.cb-product-details-carousel__slide-selector .ant-image img {
  border-radius: 4px;
  object-fit: cover;
  height: 136px;
  width: 136px;
}

.cb-product-details-carousel__slide-selector .ant-image {
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .cb-product-details-carousel__empty {
    height: 300px;
  }

  .cb-product-details-carousel .ant-carousel .ant-image img {
    max-height: 300px;
  }

  .cb-product-details-carousel__slide-selector .ant-image img {
    height: 100px;
    width: 100px;
  }
}
