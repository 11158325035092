#overlay {
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 99; 
  cursor: pointer;
}

  .chat-payment-modal-container {
    z-index: 99;
    border: #979797;
    border-radius: 8px;
    background-color: white;
    position: absolute;
    top: 50%;
    margin-top: -250px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .payment-modal-container {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
