.clients-v2-collection--card {
  width: 220px;
  height: 280px;
  box-sizing: border-box;
  border-radius: 8px;
}

.clients-v2-collection--card > .ant-card-body {
  padding: 0;
  box-sizing: border-box;
}

.clients-v2-collection--card-header {
  display: flex;
  text-align: left;
  padding: 10px 16px;
  background-color: #F2F3F6;
  border-radius: 8px 8px 0px 0px;
}

.clients-v2-collection--card-header > div {
  width: 100%;
  flex: 1;
  display: grid;
}

.clients-v2-collection--card-header-title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
}

.clients-v2-collection--card-header-subtitle {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #818181;
  min-height: 16px;
}

.clients-v2-collection--card-body {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  border-radius: 0px 0px 8px 8px;
}


.clients-v2-collection--card-body {
  flex: 1;
  height: 220px;
}

.clients-v2-collection--card-body img {
  object-fit: cover;
}

.clients-v2-collection--card-body .ant-image:nth-child(3) img {
  border-bottom-left-radius: 8px;
}

.clients-v2-collection--card-body .ant-image:nth-child(4) img {
  border-bottom-right-radius: 8px;
}
