/* stylelint-disable selector-class-pattern */
.cb-delete-bank-account-modal .new-pop-confirm__card {
  max-width: 280px;
  padding: 32px;
}

.cb-delete-bank-account-modal__title {
  margin-bottom: 26px;
  color: #000;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  text-align: center;
}

.cb-delete-bank-account-modal__subtitle {
  margin-bottom: 26px;
  color: #000;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  text-align: center;
}

.cb-delete-bank-account-modal__description {
  margin-bottom: 26px;
  color: #818181;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  text-align: center;
}

.cb-delete-bank-account-modal__footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 12px;
}

.cb-delete-bank-account-modal__footer button {
  height: 42px !important;
}
