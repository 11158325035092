.cb-chip {
  padding: 6px;
  border-radius: 4px;
  background: #e8f4ff;
  color: #33aafc;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
}
