.cb-vendor-recommendations {
  margin-top: 16px;
  text-align: left;

  &__header {
    &-title {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    &-logo {
      width: auto;
      height: 100%;
      max-height: 34px;
      max-width: 160px;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 12px;
      color:  #737373;
    }
  }
}
