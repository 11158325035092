.edisonAssistantBtn{
    width: 124px;
    background-color: #33aafc;
    border-radius: 20px;
    height: 32px;
    margin-top: 12px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    left: 37%; */
}

.edisonAssistantBtn .ant5-badge-dot {
  box-shadow: none;
}
.edisonAssistantAvatar{
    width: 22px;
    height: 22px;
    border: 3px solid #bdbdbd;
}

.edisonAssistantAvataLarge{
    width: 80px;
    height: 80px;
    border: 3px solid #bdbdbd;
}

.introEdisonToolTipOuterDiv{
    width: 340px ;
    height: 381px ;
    top: 55px ;
    border-radius: 10px;
    background-color: white;
    right: -100px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.edisonToolTipOuterDiv{
    width: 280px;
    height: 20px;
    max-height: 40px;
    top: 66px;
    left: -87px;
    border-radius: 8px;
    background-color: black;
    padding: 16px;
}

.backgroundDarkDiv{
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0px;
    background-color: rgba(0, 0, 0, .6);
    z-index: 5;
}

.edisonAITextArea {
    width: 418px;
    height: 100px;
    border-radius: 3px;
    border: 1px solid #bdbdbd;
}

/* edison popup styles */

.leadOuterCompDiv{
    width: 100%;
    max-width: 540px;
    /* height: 500px ; */
    border-radius: 10px;
    background-color: white;
    z-index: 202;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.leadOuterCompDiv-separator {
    display: flex;
    width: 1px;
    background-color: #e6e6e6;
    margin: 20px 0;
}
