.clients-v2-collection-details--header {
  display: flex;
  justify-content: space-between;
}

.clients-v2-collection-details--header-info {
  display: flex;
  flex-direction: column;
}

.clients-v2-collection-details--header-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

.clients-v2-collection-details--header-subtitle {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.clients-v2-collection-details--header-shared {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #818181;
}

.clients-v2-collection-details--header-options {
  display: flex;
}

.clients-v2-collection-details--header-options .ant-btn > svg {
  margin-right: 10px;
}

.clients-v2-collection-details--overlay .ant-dropdown-menu-item > span {
  padding: 0 !important;
}
