.cb-vendor-recommendations-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e6f4ff;
  }

  &__image {
    height: 40px;
    width: 40px;

    .ant5-image {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      & > img {
        border-radius: 4px;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 14px;
  }

  &__mpn {
    font-size: 12px;
    color: #737373;
  }
}
