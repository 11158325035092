.cb-search-bar-input {
  display: flex;
  margin-bottom: 14px;
}

.cb-search-bar-input > span {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  border-radius: 4px;
  padding: 14px 16px;
  color: #000;
  border: none;
  background-color: #f4f4f4;
}

.cb-search-bar-input.bg-white > span {
  background-color: white;
}

.cb-search-bar-input .ant-input-prefix {
  font-size: 16px;
  margin-right: 16px;
}

.cb-search-bar-input .ant-input-affix-wrapper {
  border: 2px solid #f4f4f4;
}

.cb-search-bar-input .ant-input-affix-wrapper:focus,
.cb-search-bar-input .ant-input-affix-wrapper-focused,
.cb-search-bar-input .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 2px !important;
  border: 2px solid #33aafc;
  box-shadow: none;
}

.cb-search-bar-input .ant-input {
  background-color: transparent;
  font-size: 13px;
  color: #000;
}

.cb-search-bar-input .ant-input::placeholder {
  color: #818181;
}
