.cd-tabs {

  .ant-tabs-content-holder {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #F2F3F6;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #EDEEF1;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #EAEBEE;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #BDBDBD;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #B8B8B8;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: #A8A8A8;
    }
  }



  .ant-tabs-content {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .ant-tabs-top > .ant-tabs-nav{

    padding-bottom: 8px;


    .ant-tabs-nav-wrap {
      padding: 4px;

      > .ant-tabs-nav-list {

        height: 46px;
        border-radius: 7px;
        background: #F2F3F6;
        padding: 1px;

        > .ant-tabs-tab {
          height: 46px;
          line-height: 21px;
          font-size: 14px;
          font-weight: 500;
          color: #BDBDBD;
          border-radius: 8px;
          border: 0;
          padding: 0;
          background-color: transparent;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #EDEEF1;
          }

          > .ant-tabs-tab-btn:active {
            color: #969696;
          }

          &.ant-tabs-tab-active {

            .ant-tabs-tab-btn {
              color: #000 !important;

              &:active {
                color: #b0b0b1;
              }
            }
            background: #FFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
          }
        }
      }
    }

    &::before {
      border: 0 !important;
    }
  }



}
