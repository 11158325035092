.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 53px;
}

.column {
  flex-direction: column;
}

.upload-data-container {
  margin: 50px 250px;
}

.import-positive-button {
  width: 140px;
  border: none;
  color: #fff;
  height: 45px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #33AAFC;
  margin-right: 14px;
}

.import-cancel-button {
  width: 140px;
  border: solid 1px #33AAFC;
  color: #33AAFC;
  height: 45px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  border-width: 2px;
  cursor: pointer;
  background-color: #fff;
  margin-top: 60px;
  display: block;
}

.disabled-button {
  cursor: not-allowed;
  background-color: #818181;
  border: none;
  margin-right: 14px;
  color: #fff;
}

.text-gray {
  color: #a0a0a0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.chooseFileMarginTop {
  margin-top: 90px;
}

.drag-files-span {
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  padding: 15px;
}

.or-span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 15px;
}
