.report-date-picker .msgSetTitle_DescInput {
  width: 267px;
}

.report-smaller-description {
  text-align: left;
  font-size: 10px;
  color: #818181;
  margin-bottom: 20px;
  max-width: 400px;
}

.report-description-list {
  text-align: left;
  font-size: 10px;
  color: #818181;
  max-width: 400px;
}

.payment-activity-list__empty {
  margin-top: 24px;
  padding: 30px 40px 60px;
  background: #f2f3f6;
}

.bank-transaction-list__empty {
  margin-top: 24px;
  padding: 30px 40px 60px;
  background: #f2f3f6;
}

.report-cards {
  margin-top: 25px;
}

.report-cards span {
  text-align: left;
  display: block;
  font-size: 10px;
  color: gray;
}
.report-cards a {
  text-align: left;
  display: block;
  color: #007bd0 !important;
  text-decoration: none !important;
  font-weight: 500;
}

.report-last-run {
  font-size: 12px;
  font-family: Poppins;
  color: #bdbdbd;
}

.card-column-rows {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.report-asct-msg-input {
  box-sizing: border-box; /* This tells the browser how to determine the box sizing - required for interior padding on inputs */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  width: 392px;
  height: 50px;
  font-size: 14px;
  background-color: white;
  color: #818181;
  font-family: Poppins;
  border-radius: 3px;
  border: solid 1px #818181;
  display: block;
  margin-right: 15px;
  margin-bottom: 15px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  padding-left: 22px;
  /* text-indent: 22px; */
  /* padding-right: 22px; */
}

.report-asct-msg-input option {
  font-size: 14px;
  background-color: white;
  color: #818181;
  font-family: Poppins;
  padding-left: 22px !important;
}

.report-asct-msg-row {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

.report-asct-msg-row div {
  line-height: 27px;
  text-align: left;
}
.report-asct-msg-row div img {
  margin-right: 10px;
  vertical-align: middle;
}

.report-asct-msg-row div span {
  font-size: 12px;
  height: 100%;
  /* line-height: 50px; */
  color: #bdbdbd;
  width: 100%;
  background-color: 'red';
}

.asct-msg-run-btn {
  float: right;
  margin-right: 15px;
}

/* FLEX STYLES for reports since they behave differently in responsiveness than the general flex styles */
.report-flex-row-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.report-flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-around;
}

.report_inputStyle {
  padding-left: 18px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  box-sizing: border-box;
}

.reportMobile {
  margin-top: 0px;
}

.report--link-button {
  color: #007bd0;
}

.report--link-button:hover {
  opacity: 0.8;
}

/* REACT DATEPICKER STYLES */

.react-datepicker__current-month,
.react-datepicker-year-header {
  width: 100%;
  justify-content: center;
  display: flex;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  text-align: center !important;
}

.react-datepicker__month {
  text-align: center !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  text-align: center !important;
}

.react-datepicker__header--time {
  padding-bottom: 32px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #33aafc;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #33aafc;
}

.table-scroll {
  display: block;
  overflow: auto;
}

@media only screen and (max-width: 1200px) {
  .report-asct-msg-row {
    flex-direction: column;
  }
  .report-flex-row-nowrap {
    flex-direction: column;
  }
  .report-flex-row-wrap {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .reportMobile {
    margin-top: 215px;
  }
}

@media only screen and (max-width: 800px) {
  /* .report-asct-msg-row:first-child {
    margin-left: 20px;
    } */
}
