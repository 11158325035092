span.cb-tag {
  background-color: #000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 18px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  border: none;
}

span.cb-tag__icon {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
}
