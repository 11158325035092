.pop-confirm-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.50);
  z-index: 999;
  overflow: hidden;
}

.view-all-wa-templates-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.16);
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-all-wa-templates-container {
  z-index: 99;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  /* max-height: 90vh; */
  overflow-y: scroll;
  margin-top: 110px;
  max-height: calc(100vh - 120px);
}

.pop-confirm-container {
  z-index: 99;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.pop-confirm-container h2 {
  font-weight: 800;
}

.pop-confirm-container-large {
  z-index: 99;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 20vh;
  left: 20vw;
  margin: 0 0 0 10vw;
}

@media only screen and (max-width: 800px) {
  .pop-confirm-container {
    /* width: 50%;
    min-width: 300px;
    position: relative;
    left: 0;
    margin-left: calc(50% - 150px);
    align-content: center;
    vertical-align: middle;
    margin-top: 0px; */
    /* padding-top: 0px; */
    margin-left: -200px;
    min-width: 300px;
    align-content: center;
    vertical-align: middle;
  }
}
