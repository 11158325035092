.products-v2-list {
  width: 232px;
  padding: 20px;
  text-align: left;
  border-right: 1px solid #f0f0f0;

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 34px;
    margin-bottom: 16px;
  }

  &__logo {
    width: auto;
    height: 100%;
    max-height: 34px;
    max-width: 160px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  &__search {
    margin-bottom: 16px;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}
