div.cb-checklist-dropdown-popover {
  position: relative;
  left: -16px;
  width: calc(100% + 32px);
  top: -12px;
}

/* SearchBar styles (start) */
div.cb-checklist-dropdown-popover .cb-search-bar-input {
  padding: 16px;
  margin-bottom: 0;
}

div.cb-checklist-dropdown-popover .cb-search-bar-input .ant-input-prefix {
  margin-right: 8px;
}
/* SearchBar styles (end) */

div.cb-checklist-dropdown-popover__list .ant-list-item:hover {
  background-color: #e5f2fb;
}

button.cb-checklist-dropdown-popover__button {
  position: absolute;
  height: 50px;
  bottom: -62px;
  font-weight: 500;
  border-color: #33aafc;
  background-color: #33aafc;
}

button.cb-checklist-dropdown-popover__button:hover {
  border-color: #44bbfd;
  background-color: #44bbfd;
}

/* Scrollbar width */
.cb-checklist-dropdown-popover .infinite-scroll-component::-webkit-scrollbar {
  width: 8px;
}

/* Scrollbar track */
.cb-checklist-dropdown-popover .infinite-scroll-component::-webkit-scrollbar-track {
  background: #fff;
}

/* Scrollbar handle */
.cb-checklist-dropdown-popover .infinite-scroll-component::-webkit-scrollbar-thumb {
  min-height: 36px;
  border-radius: 8px;
  background: #c4c4c4;
}

/* Scrollbar handle on hover */
.cb-checklist-dropdown-popover .infinite-scroll-component::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}
