/* stylelint-disable selector-class-pattern */
.cb-integrations-settings-options {
  margin-bottom: 32px;
}

.cb-integrations-settings-options__switch {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.cb-integrations-settings-options__switch .ant-switch-checked {
  background: #000;
}

.cb-integrations-settings-options__switch-label {
  margin-left: 12px;
}

.cb-integrations-settings-options__switch-label p:first-child {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}

.cb-integrations-settings-options__switch-label p:last-child {
  margin: 0;
  color: #979797;
  font-weight: 400;
  font-size: 10px;
}

.cb-integrations-settings-options__modal {
  text-align: center;
}

.cb-integrations-settings-options__modal p {
  font-weight: 600;
  font-size: 16px;
}

.cb-integrations-settings-options__modal-help {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

p.cb-integrations-settings-options__modal-description {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.cb-integrations-settings-options__switch-tooltip .ant-tooltip-inner {
  padding: 18px;
  border-radius: 10px;
  background-color: #454545;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
  text-align: justify;
}

.cb-integrations-settings-options__switch-tooltip-icon {
  margin-bottom: 16px;
  margin-left: 6px;
  color: '#9c9c9c';
  font-size: 12px;
}
