.cb-products-list-table {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  text-align: left;
  position: relative;
  left: -8px;
}

span.cb-products-list-table-item__image {
  background-color: #f2f3f6 !important;
  border-radius: 4px;
}

div.logo-sm-txt {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-size: 6px;
  color: #fff;
  background-color: none !important;
  border: none !important;
}
