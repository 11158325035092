.clients-v2-collection-product-details {
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.clients-v2-collection-product-details > section {
  margin: 12px 0;
}

.clients-v2-collection-product-details hr {
  margin-bottom: 24px;
}

.clients-v2-collection-product-details--name {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
  margin-bottom: 4px;
}

.clients-v2-collection-product-details--title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  margin-bottom: 12px;
}

.clients-v2-collection-product-details--sku {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  margin-bottom: 12px;
}

.clients-v2-collection-product-details--price {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
}

.clients-v2-collection-product-details--section {
  display: flex;
  margin-bottom: 12px;
}

.clients-v2-collection-product-details--section > div {
  display: flex;
  flex-direction: column;
}

.clients-v2-collection-product-details--section > div:first-child {
  margin-right: 12px;
}
