.products-v2 {
  width: 100%;
  background-color: #fff;
  height: 100%;

  & > div {
    height: 100%;
    display: flex;
  }
}
