/* stylelint-disable selector-class-pattern */
.cb-integrations-modal {
  position: relative;
  text-align: center;
}

.cb-integrations-modal__title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cb-integrations-modal__subtitle {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 13px;
}

.cb-integrations-modal__error {
  position: relative;
  top: -12px;
  color: #ff7a7a;
  font-size: 12px;
  line-height: 18px;
}

.cb-integrations-modal__input-error input::placeholder {
  color: #ff7a7a;
  font-weight: 500;
}

.cb-integrations-modal__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  color: #979797;
}

.cb-integrations-modal__loader span:first-child {
  margin-bottom: 24px;
  font-size: 24px;
}

.cb-integrations-modal__loader span:last-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
