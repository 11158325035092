.clients-v2-collections {
  padding: 24px;
  width: 100%;
}

.clients-v2-collections--container {
  display: flex;
}

.clients-v2-collections--back {
  margin-top: 20px;
  margin-left: 20px;
  width: 20px !important;
}

