.upload-product-image {
  width: 88px;
  height: 88px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.upload-product-image-filled {
  border: 0.5px solid #F2F3F6;
  border-radius: 6px;
}
.upload-product-image-empty {
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
}

.delete-product-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.delete-product-image:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.product-draggeOver {
  background-color: rgba(0, 0, 0, 0.25);
}
