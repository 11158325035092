/* stylelint-disable selector-class-pattern */
.cb-integrations-settings-stores {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  margin-bottom: 50px;
  border-radius: 8px;
  background-color: #f2f3f6;
}

.cb-integrations-settings-stores__loader {
  display: flex;
  flex-direction: column;
  color: #979797;
}

.cb-integrations-settings-stores__loader span:first-child {
  margin-bottom: 24px;
  font-size: 24px;
}

.cb-integrations-settings-stores__loader span:last-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.cb-integrations-settings-stores__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 580px;
  padding: 24px !important;
}

.cb-integrations-settings-stores__list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.cb-integrations-settings-stores__input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cb-integrations-settings-stores__input-title {
  margin-bottom: 24px;
  font-weight: 400;
}

.cb-integrations-settings-stores__input-icon {
  flex: 1;
  margin-right: 16px;
  margin-bottom: 24px;
  text-align: right !important;
}

.cb-integrations-settings-stores__input {
  width: 100%;
  max-width: 300px;
}

.cb-integrations-settings-stores__input-group div.cb-select .ant-select .ant-select-selector {
  border: 1px solid #818181;
  background-color: #fff;
}

.cb-integrations-settings-stores .cb-pagination-v2 {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.cb-integrations-settings-stores .cb-pagination-v2 .ant-pagination .ant-pagination-prev,
.cb-integrations-settings-stores .cb-pagination-v2 .ant-pagination .ant-pagination-next,
.cb-integrations-settings-stores .cb-pagination-v2 .ant-pagination .ant-pagination-item {
  padding: 2px;
  background: #fff !important;
}
