.table-container {
  display: flex;
  justify-content: space-between;
}

.import-table {
  width: fit-content;
  max-width: 100%;
  border: solid 1px #818181;
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
}

.import-header {
  background-color: #f3f3f3;
  padding: 0;
  margin: 0;
}

.cell {
  height: 47px;
  font-size: 12px;
  border: solid 0.25px #d0d0d0;
  padding: 0;
  margin: 0;
  padding: 0 14px;
  text-align: left;
  white-space: nowrap;
}

.border-cells {
  background-color: #f3f3f3;
  font-weight: 600;
}

.highlighted-cells {
  background-color: #e8f6ff;
}

.confirm-table {
  width: 100%;
  max-width: 582px;
  border: solid 1px #818181;
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  margin-bottom: 29px;
}

.confirm-cells {
  width: 100%;
}

.bold {
  font-weight: 600;
}
