.layout-main-v2 {
  height: 100vh;
}
.layout-main-w-payment-banner-v2 {
  padding: 105px 0 0 0;
  height: 100vh;
  width: 100vw;
}

.standard-width-with-sidebar-v2 {
  padding-left: 200px;
}
