.cb-sales-opportunity-card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.11);
  position: relative;
  padding: 12px 16px;
  border-left: 10px solid #33AAFC;
  box-sizing: border-box;
  width: 100%;

  &--amount {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  &--created-date {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }

  &--name {
    font-weight: 400;
    font-size: 13px;
    color: #9C9C9C;
    margin: 0;
  }

  &--stage {
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    padding: 6px 12px;
    position: absolute;
    top: 12px;
    right: 16px;
    background: #EBEBEB;
    color: #9C9C9C;
  }

  &--next-button {
    position: absolute !important;
    bottom: 12px;
    right: 16px;
  }
}
