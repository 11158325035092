.clients-v2-product-details--header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 14px;
  padding-bottom: 6px;
}

.clients-v2-product-details--header-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.clients-v2-product-details--header-options {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.clients-v2-product-details--header-options .ant-btn > svg {
  margin-right: 10px;
}
