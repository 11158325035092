.ant5-phone-input {

  width: 100%;
  .ant-input, .ant-input-group-addon {
    border-radius: 6px;
  }
}

.ant-phone-input-wrapper {
  width: 100%;
}
