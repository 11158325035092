.clientFilter{
    z-index: 20;
    width: 350px;
    position: absolute;
    left: 0px;
    text-align: left;
    background-color: white;
    height: 100vh;
    top: 105px;
}

.clientBulkActionAndAll{
    width: 275px;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 18px;
    margin-top: 15px;
}

.clientAvatarLI {
    width: 100%;
    height: 56px;
    display: grid;
    grid-template-columns: 40px 50px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.clientAvatarLI:hover{
    background-color: #F2F3F6;
}

.clientAvatarInitials {
    padding-top: 4px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 5px;
    margin-bottom: 9px;
}

.clientIcon{
    color: #000;
    display: flex;
    width: 28px;
    height: 28px;
}
.clientIcon:hover{
    color: #33AAFC;
}

.clientMultiTagSearch{
    z-index: 1;
    border-radius: 3px;
    border: none;
    width: 330px;
    height: 50px;
    padding-top: 0px;
    padding-left: 50px;
    background-color: #f4f4f4;
    background-image: url(https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg);
    background-repeat: no-repeat;
    /* background-position: 15px 14px; */
    background-position: 20px 11px;
    outline: none;
    margin-left: 2px;
}
.clientMultiAsctSearch{
    z-index: 1;
    border-radius: 3px;
    border: none;
    width: 330px;
    height: 50px;
    padding-top: 0px;
    padding-left: 40px;
    background-color: #f4f4f4;
    background-image: url(https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg);
    background-repeat: no-repeat;
    /* background-position: 15px 14px; */
    background-position: 10px 8px;
    outline: none;
    margin-left: 2px;
}
