.products-v2-sidebar {
  background-color: #f2f3f6;
  padding: 20px;
  width: 240px;
  text-align: left;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &-title {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
  }

  &__menu {
    margin-bottom: 30px;

    &-title {
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
    }

    &-list {
      overflow-y: auto;
      height: calc(100vh - 360px);
    }

    &-item {
      width: 100%;
      text-align: left !important;

      &-selected {
        background: #e6f4ff !important;
        color: #1677ff !important;
      }
    }
  }
}
