/* associate header should always be align with the table, and the table is set at a fixed width, despite if the screen is bigger or not */
.associateHeader {
  right: 15px;
  position: absolute;
  height: 50px;
  z-index: 10;
  width: 300px;
  margin-top: 15px;
}

.editAssociate {
  font-size: 16px;
  font-weight: 500;
  color: #33aafc !important;
}

.associateHeader input {
  color: #000;
  position: absolute;
  left: 0;
  width: 378px;
  height: 44px;
  font-size: 16px;
  font-family: Poppins;
  border-radius: 3px;
}
.associateHeader a {
  position: absolute;
  background-color: #1769af;
  right: 0;
  border-radius: 3px;
  width: 207px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  color: #fff;
  font-family: Poppins;
  z-index: 0 !important;
}
.associateHeader a img {
  vertical-align: middle;
  margin-right: 13px;
}

.addAsctInputRows {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  /* width: 70%; */
}

/* .addAsctInputRows > div:first-of-type {
    margin-right: 15px;
} */
/* .addAsctInputRows div {
    display: flex;
    flex-direction: row;
    margin-right: 15px;
} */

.addAssociateSpan {
  width: 425px;
  height: 50px;
  text-align: left;
  margin-bottom: 15px;
  display: flex;
  margin-right: 50px;
}

.addAssociateInputs {
  width: 375px;
  height: 46px;
  border-radius: 3px;
  border: solid 1px #818181;
  color: #000;
  font-size: 13px;
  font-family: Poppins;
  margin-bottom: 15px;
  margin-right: 15px;
  padding-left: 10px;
}

.associateText {
  width: 400px;
  text-align: left;
}

.first_last_name {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-user.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
  padding-left: 60px;
}

.first_last_name::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.associate_phone {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-phone.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
  padding-left: 60px;
}

.associate_phone::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.associate_email {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/env-closed.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
  padding-left: 60px;
}

.associate_email::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.associate_pos {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/address-card-regular.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
  padding-left: 60px;
}

.associate_pos::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.associate_schedule {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/schedule.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
  padding-left: 60px;
}

.associate_role {
  padding-left: 15px;
  width: 422px;
  height: 48px;
}

.associateCheckbox {
  width: 700px;
  justify-content: space-between;
}

.associate_admin {
  display: flex;
  flex-direction: column !important;
}

.assoc_report_div {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.assoc_report_head {
  margin-left: 20px;
  font-size: 18px;
  color: #000000;
}

.assoc_reports {
  text-align: left;
}

.assoc_bottom {
  width: 580px;
  display: flex;
  flex-direction: column;
}

.assoc_report_heading {
  font-size: 16px;
  color: #33aafc;
  margin-top: 20px;
}

.last_run {
  font-size: 12px;
  color: #bdbdbd;
}

.assoc_card {
  width: 585px;
  height: 350px;
  border-radius: 8px;
  border: solid 1px #979797;
}

.assoc_outreach {
  display: flex;
}

.updated_when {
  margin-top: 25px;
  width: 185px;
  height: 17px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #bebebe;
}

.assoc_card_head {
  display: flex;
  height: 45px;
}

.assoc_card_title {
  max-width: 210px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #353538;
  text-align: left;
  margin-right: 10px;
}

.associate_header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 60px;
  max-width: 1200px;
  height: auto;
}

.associate_details {
  display: flex;
  height: auto;
}

.pic_details {
  text-align: left;
  margin-left: 30px;
}

.pic_details li {
  margin: 0px 0px 10px 0px;
  display: inline;
  position: inherit;
}

.task_bar {
  display: flex;
  max-width: 1200px;
  flex-direction: row;
}

.assoc_man_feat {
  text-align: right;
  width: 760px;
}

.reminder_bar {
  display: flex;
  flex-direction: row;
  width: 700px;
  margin-bottom: 25px;
  text-align: left;
}

.reminder_bar a,
.assoc_man_feat a {
  width: 150px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #31b6ff;
  margin-right: 45px;
}

.reminder_bar img,
.assoc_man_feat img {
  margin-right: 10px;
}

.assoc_image {
  width: 104px;
  height: 104px;
  border-radius: 50%;
  object-fit: cover;
}

.assoc_last_time {
  text-align: left;
  margin-top: 25px;
  width: 88px;
  height: 17px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  color: #bebebe;
}

.assoc_chart {
  margin-top: 30px;
}

.assoc_time {
  display: flex;
  flex-direction: row;
}

.assoc_timeliness {
  width: 840px;
  max-width: 840px;
  height: 350px;
  margin-right: 20px;
  border-radius: 8px;
  border: solid 1px #979797;
  margin-top: 20px;
}

.assoc_days_late {
  width: 330px;
  min-width: 330px;
  border-radius: 8px;
  border: solid 1px #979797;
  margin-top: 20px;
}

.assoc_late {
  text-align: left;
  margin-left: 10px;
  font-size: 78px;
  font-weight: 600;
  margin-top: 0px;
}

.Associate_image {
  height: 26px;
}

.Associate_image img {
  object-fit: cover;
}

/* for the home page that is using same styles */
.homePipelineGraph {
  width: 460px;
  height: 200px;
  border-radius: 8px;
  border: solid 1px #979797;
  margin-top: 20px;
  margin-right: 20px;
}

.tabbed_three_header {
  width: 100%;
  height: auto;
  /* max-height: 1200px; */
  border-radius: 8px;
  /*border: solid 1px #979797;*/
  margin-bottom: 0px;
}

.pathActivity {
  text-align: left;
  margin-left: 15px;
  margin-top: 0px;
  width: 275px;
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
  color: #353538;
}

@media only screen and (max-width: 1200px) {
  .assoc_time {
    display: flex;
    flex-direction: column;
  }
  .assoc_timeliness {
    max-width: 100%;
    width: 100%;
  }
  .assoc_outreach {
    display: flex;
    flex-direction: column;
  }
  .assoc_card {
    margin-bottom: 20px;
    width: 100%;
  }

  .assoc_days_late {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .assoc_bottom {
    width: 300px;
  }

  .reminder_bar a,
  .assoc_man_feat a {
    width: 150px;
    margin-right: 25px;
  }

  .assoc_man_feat {
    text-align: left;
    margin-bottom: 15px;
  }
  .task_bar {
    flex-direction: column;
  }

  .associate_header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 800px) {
  /* .addAssociateInputs {
  width: 300px;
} */
}
