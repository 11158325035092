span.cb-input,
input.cb-input {
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  padding: 14px;
  border: 1px solid #818181;
  border-radius: 4px;
  color: #000;
  box-shadow: none !important;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
}

span.cb-input::placeholder,
input.cb-input::placeholder {
  color: #bdbdbd;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
}

span.cb-input:hover,
span.cb-input:focus,
span.cb-input:active,
input.cb-input:hover,
input.cb-input:focus,
input.cb-input:active {
  padding: 13px;
  border: 2px solid #33aafc;
  border-right-width: 2px !important;
}

span.cb-input,
span.cb-input:hover,
span.cb-input:focus,
span.cb-input:active,
input.cb-input,
input.cb-input:hover,
input.cb-input:focus,
input.cb-input:active {
  transition: none !important;
}
