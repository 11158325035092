.cb-radio-group .ant-radio-group {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.cb-radio-group .ant-radio-group-large .ant-radio-button-wrapper,
.cb-radio-group .ant-radio-group-large .ant-radio-button-wrapper:hover,
.cb-radio-group .ant-radio-group-large .ant-radio-button-wrapper:focus {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  line-height: 21px;
  font-size: 14px;
  height: 48px;
  color: #818181;
  background-color: #f5f6f8;
  border: 0;
}

.cb-radio-group .ant-radio-group {
  border-radius: 8px !important;
  background-color: #F2F3F6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.cb-radio-group.white .ant-radio-group .ant-radio-button-wrapper,
.cb-radio-group.white .ant-radio-group .ant-radio-button-wrapper:hover,
.cb-radio-group.white .ant-radio-group .ant-radio-button-wrapper:focus {
  background-color: white;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.cb-radio-group .ant-radio-group .ant-radio-button-wrapper-checked,
.cb-radio-group .ant-radio-group .ant-radio-button-wrapper-checked:hover,
.cb-radio-group .ant-radio-group .ant-radio-button-wrapper-checked:focus {
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  border: 0;
}

.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus,
.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked::before,
.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked:focus,
.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked:hover,
.cb-radio-group.not-transparent .ant-radio-button-wrapper-checked {
  background-color: white !important;
}
