div.cb-products-grid-table-item {
  width: 180px;
  height: 252px;
  text-align: left;
  border-radius: 6px !important;
}

div.cb-products-grid-table-item__image {
  height: 180px;
  width: 180px;
  background-color: #f2f3f6;
  border: 1px solid #f2f3f6;
  box-sizing: border-box;
  border-radius: 6px !important;
}

div.cb-products-grid-table-item__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

div.cb-products-grid-table-item .ant-card-body {
  padding: 16px;
}

h4.cb-products-grid-table-item__title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.5px;
  margin-bottom: 6px;
}

p.cb-products-grid-table-item__description {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  margin: 0;
}

div.cb-products-grid-table-item__unavailable {
  position: absolute;
  left: 50%;
  top: 20px;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, 0);
  background-color: #f2f3f6;
  text-align: center !important;
  border-radius: 6px 6px 0 0 !important;
}

.cb-products-grid-table-item__price {
  position: absolute;
  z-index: 99;
  bottom: 80px;
  right: 8px;
  width: 86px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBEBEB;
  color: #000;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 10px;
  border-radius: 4px;
}

div.cb-products-grid-table-item__unavailable img {
  max-width: 100px;
  height: 100px;
}

div.logo-txt {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 45px;
  color: #fff;
  background-color: none !important;
  border: none !important;
}

/* out of stock styles */

div.cb-product-details-grid__out-of-stock {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
}

div.cb-product-details-list__out-of-stock {
  position: absolute;
  z-index: 10;
  right: 115px;
  top: 22px;
}
