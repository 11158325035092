
div.drag-and-drop-container {
  position: relative;
  > div.drag-and-drop-content {
    display: none
  }
}

div.draggeOver {
  > div.drag-and-drop-content {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #FFF;
    opacity: 0.75;
    top: 0;
    left: 0;
    z-index: 999;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 85%;
      height: 85%;
      border: 1px dashed #FFF;
      border-radius: 4px;

      > span {
        font-size: 26px;
        font-weight: 700;
        margin-top: 16px;
      }
      > p {
        font-size: 12px;
      }
    }
  }

}
