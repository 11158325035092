#no_DescTitle {
  display: none;
}

.msgSetTitle_DescContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 30px;
}

.msgSetTitle_DescTitle {
  margin-bottom: 6px;
  display: block;
  text-align: left;
  color: #000000;
}

.msgSetTitle_Div {
  width: 375px;
  max-width: 100%;
}

.msgSetTitle_DescContainer input,
textarea {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
}

.msgSetTitle_DescInput {
  right: 0;
  position: relative;
  text-align: left !important;
}

input[type='number'] {
  /* padding-left: 30px; */
  text-align: left;
}

/* media query */

@media only screen and (max-width: 1200px) {
  .msgSetTitle_DescContainer {
    flex-direction: column;
  }

  .msgSetTitle_DescContainer input,
  textarea {
    float: left;
  }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 450px) {
  .msgSetTitle_DescDesc {
    padding-left: 20px;
    width: 350px;
  }
}
