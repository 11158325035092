/* stylelint-disable selector-class-pattern */
.cb-integrations {
  width: 100%;
}

.cb-integrations .layoutDesc {
  margin-top: 34px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cb-integrations .layoutTitle {
  margin: 0;
  color: #000;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
}
