.cb-sales-opp-report {
  &__inner-table {
    display: flex;

    & > div,
    & > ul {
      flex: 1;
      margin-right: 12px;
    }
  }

  &__timeline-item {
    display: flex;
    flex-direction: column;

    &:last-child {
      padding-bottom: 0 !important;
    }
  }

  &__timeline-item .ant-timeline-item-head {
    background-color: #fafafa;
  }

  &__timeline-icon {
    color: #8C8C8C;
  }

  &__timeline-icon-danger {
    color: #FF4D4F;
  }

  &__timeline-subtitle {
    font-size: 12px;
    color: #8C8C8C;
  }
}
