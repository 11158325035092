.left-modal {
  position: absolute;
  /* width: 380px; */
  width: 330px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;
}

.modal-layout-main {
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  top: 57px;
}
.modal-layout-main-w-payment-banner {
  height: calc(100vh - 107px);
  max-height: calc(100vh - 107px);
  top: 107px;
}

.left-modal-content {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 32px;
  overflow-x: hidden;
  overflow-y: auto;
}

.checkboxItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.checkboxItem .checkboxItem-text {
  padding: 2px 6px 0 0;
  font-size: 14px;
}
.checkboxItem .checkboxItem-only {
  display: none;
  padding: 1.75px 10px;
  background: #E8F4FF;
  border-radius: 4px;
  color: #1769AF;
  font-weight: 600;
  font-size: 12px;
}

.checkboxItem:hover .checkboxItem-only {
  display: flex;
}

.filter-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.filter-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.filter-item-header-title {
  font-weight: 600;
  font-size: 16px;
}

.filter-item-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  margin: 4px 0 16px;
  width: 100%;
}

.filter-item-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  color: #1769AF;
  font-weight: 600;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
}

.filter-item-clear:hover {
  background-color: #E8F4FF;
}

.filter-item-list-toggle {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 11px 0;
}

.filter-item-list-toggle-span {
  margin-top: 2px;
  font-weight: 400;
  margin-left: 10px;
}

.filter-item-list-content {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #BDBDBD #F2F3F6;
}

.filter-item-input {
  width: calc(100% - 4px);
  height: 50px;
}

.filter-item-list-content::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}
.filter-item-list-content::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #F2F3F6;
}
.filter-item-list-content::-webkit-scrollbar-track:hover {
  background-color: #EDEEF1;
}

.filter-item-list-content::-webkit-scrollbar-track:active {
  background-color: #EAEBEE;
}

.filter-item-list-content::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #BDBDBD;
}
.filter-item-list-content::-webkit-scrollbar-thumb:hover {
  background-color: #B8B8B8;
}
.filter-item-list-content::-webkit-scrollbar-thumb:active {
  background-color: #A8A8A8;
}

.left-modal::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}
.left-modal::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #F2F3F6;
}
.left-modal::-webkit-scrollbar-track:hover {
  background-color: #EDEEF1;
}

.left-modal::-webkit-scrollbar-track:active {
  background-color: #EAEBEE;
}

.left-modal::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #BDBDBD;
}
.left-modal::-webkit-scrollbar-thumb:hover {
  background-color: #B8B8B8;
}
.left-modal::-webkit-scrollbar-thumb:active {
  background-color: #A8A8A8;
}

