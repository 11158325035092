div.cb2-select {
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: #f4f4f4;
  padding: 0 0px 0 16px;
  border: 2px solid #f4f4f4;
  border-radius: 4px;
}

div.cb2-select:hover {
  border: 2px solid #40a9ff;
}

.cb2-select-focus {
  border: 2px solid #40a9ff !important;
}

div.cb2-select .ant5-select {
  border: 0;
  height: 48px;
  background-color: transparent;
}

div.cb2-select .ant5-select .ant5-select-selector {
  border: 0;
  background-color: transparent;
}

div.cb2-select__prefix .ant5-select .ant5-select-selector {
}

div.cb2-select .ant5-select .ant5-select-selection-item .cb2-select__suffix {
  display: none;
}

div.cb2-select .ant5-select .ant5-select-selection-item,
div.cb2-select .ant5-select .ant5-select-selection-search,
div.cb2-select .ant5-select .ant5-select-selection-placeholder,
div.cb2-select .ant5-select .ant5-select-selection-search-input {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  line-height: 48px;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  text-align: left;
}

div.cb2-select__prefix .ant5-select .ant5-select-selection-search {
  align-self: flex-start;
}

div.cb2-select .ant5-select .ant5-select-selection-placeholder {
  color: #818181;
}

div.cb2-select .ant5-select:not(.ant5-select-disabled).ant5-select:not(.ant5-select-customize-input) .ant5-select-selector,
div.cb2-select
  .ant5-select-focused:not(.ant5-select-disabled).ant5-select:not(.ant5-select-customize-input)
  .ant5-select-selector {
  border-right-width: 2px !important;
  box-shadow: none !important;
}

div.cb2-select .ant5-select-arrow .ant5icon-spin,
div.cb2-select .ant5-select-arrow .ant5icon-loading {
  color: #33aafc;
}

div.cb2-select__prefix .ant5-select-arrow .fa-angle-down,
div.cb2-select__prefix .ant5-select-arrow .ant5icon-search {
  display: none;
}

div.cb2-select__dropdown {
  padding: 0;
}

div.cb2-select__dropdown .ant5-select-item-option {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #000;
}

div.cb2-select__dropdown .ant5-select-item-option-disabled {
  background-color: #f5f5f5;
  color: #999;
}

div.cb2-select__dropdown .ant5-select-item-option-selected:not(.ant5-select-item-option-disabled) {
  background-color: #e5f2fb;
}
