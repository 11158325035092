.radio-btn > input[type='radio'] {
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #818181;
}

.radio-btn > input[type='radio']:checked::after {
  box-sizing: border-box;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #33aafc;
}
