.payment-settings-textarea > .msgSetTitle_DescInput {
  width: 100%;
}

.payment-settings-textarea > .msgSetTitle_DescInput textarea {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 40px;
}
