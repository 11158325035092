.descOverInput {
  text-align: left;
  color: #000000;
}
.descOverInput span {
  padding-bottom: 12px;
  line-height: 30px;
}
.descOverInput span svg {
  margin-right: 10px;
  height: 15px;
}

@media only screen and (max-width: 800px) {
  .descOverInput span {
    padding-left: 20px;
  }
}
