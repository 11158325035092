.wrap-table-container {
  width: 600px;
  height: 455px;
  /* height: 310px; */
  border-radius: 8px;
  border: solid 1px #979797;
}

.wrap-table-header {
  width: 100%;
  height: 63px;
  /* border-bottom: solid 1px #979797;
  border-radius: 8px 8px 0px 0px; */
  padding-bottom: 3rem;
  vertical-align: middle;
}
.wrap-table-header span {
  line-height: 63.5px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .wrap-table-container {
    width: 99% !important;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 800px) {
}
