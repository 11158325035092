.satisfaction_container {
  z-index: 120;
  border: #979797;
  border-radius: 8px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -131px;
  margin-left: -201px;
}

.smileyDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  left: 80px;
  top: 123px;
}

.satisfactionText {
  right: 65px;
  top: 64px;
  position: absolute;
}

.face {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.sadFace {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/face-sad.svg");
  background-color: #ff7c7c;
  background-repeat: no-repeat;
  background-position: 14px 14px;
}
.mehFace {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/face-meh.svg");
  background-color: #ffca00;
  background-repeat: no-repeat;
  background-position: 14px 14px;
}
.happyFace {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/face-happy.svg");
  background-color: #75d6a1;
  background-repeat: no-repeat;
  background-position: 11px 13px;
}
.sadFace:hover {
  background-color: #ff4d4d;
}
.mehFace:hover {
  background-color: #dfb100;
}
.happyFace:hover {
  background-color: #39bf76;
}

.greenDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  left: 175px;
  top: 135px;
}
