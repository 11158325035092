.cb-sales-opportunities {
  margin-left: 22px;
  margin-right: 22px;
  text-align: left;
  // width: 100%;
  max-width: 878px;
}

.cb-sales-opportunities--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 12px;
}

.cb-sales-opportunities--header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cb-sales-opportunities--header-title {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.cb-sales-opportunities--add-button > span {
  font-weight: 600;
  font-size: 16px;
}

.cb-sales-opportunities--view-all-button {
  color: #33AAFC;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 6px;
  flex: 1;
  text-align: right;
}

.cb-sales-opportunities--view-all-button-empty {
  color: #33AAFC;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  flex: 1;
  text-align: right;
}

.cb-sales-opportunities--body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.cb-sales-opportunities--body-helper {
  font-size: 12px;
  font-weight: 400;
  color: #818181;
}

