.pageDescription {
  text-align: left;
  width: 475px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818181;
  margin-top: 122px;
}

.tags-layout {
  margin-right: 0px;
  margin-left: 265px;
  width: calc(100vw - 330px);
}

/* table area */

.tags_row2 div {
  float: right;
  margin-right: 1vw;
}

.table-header-grey {
  color: #565656;
}

.tags_row3 div {
  float: right;
  margin-right: 1vw;
}

.tags_row4 div {
  float: right;
  margin-right: 1vw;
}
.tags_row5 div {
  float: right;
  margin-right: 2vw;
}

.tags_row6 div {
  float: right;
  margin-right: 1vw;
}

.tags_RowColor {
  width: 21px;
  height: 21px;
  background-color: purple;
  border-radius: 50%;
}

/* add tag page */

.tagNameDiv {
  display: flex;
}

.add-edit-tag-layout {
  height: calc(100vh - (28px + 890px));
}

.add-tag-name::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.add-tag-name {
  border-radius: 3px;
  border: none;
  /* width: 500px; */
  max-width: 75vw;
  height: 40px;
  padding-top: 5px;
  padding-left: 60px;
  /* background-color: #f4f4f4; */
  border: 1px solid #818181;
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-tag.svg");
  background-repeat: no-repeat;
  background-position: 15px 12px;
}

.tag-sort-color-div {
  padding-top: 6px;
  height: 28px;
  margin-top: 30px;
  padding-left: 10px;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  overflow-x: scroll;
}

.SortColor {
  border-radius: 50%;
  height: 21px;
  margin-right: 35px;
  width: 21px;
  vertical-align: middle;
}

.tag-selected-color-ring {
  vertical-align: middle;
  width: 33px;
  height: 33px;
  border-width: 3px;
  border-style: solid;
  border-color: black;
  border-radius: 50%;
  margin: -6px 30px -6px -7px;
  padding: 3px;
}

.data_row1 {
  padding-left: 40px;
}

.kiosk-save {
  width: 100%;
  max-width: 866px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 56px;
}

.kiosk-save div {
  /* max-width: 439px; */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap-reverse; */
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.kiosk-save-button {
  margin-left: 15px;
}

.kiosk-save button p {
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
}

.edit-message-auto-table {
  height: 196px !important;
  /* overflow-y: scroll; */
  border: none !important;
  margin-top: 50px;
  padding-bottom: 0px !important;
  width: auto !important;
}

.edit-tags-auto-table {
  border: none !important;
  height: 320px !important;
  overflow-y: scroll;
  /* margin-top: 50px; */
  padding-bottom: 0px !important;
  width: 100% !important;
}

.edit-tags-auto-container {
  margin-top: 24px;
  width: 850px;
  max-width: 100%;
}

.whatsapp-auto-container {
  width: 850px;
  max-width: 100%;
}

.whatsapp-auto-five-templates-table {
  border: none !important;
  overflow-y: scroll;
  padding-bottom: 0px !important;
  width: 100% !important;
}

.tags-auto-head-btn {
  font-family: Poppins;
  border: none;
  height: 99%;
  width: 151px;
  margin-right: 2px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0);
}

.tag-clients-number {
  text-align: right !important;
  padding-right: calc(20% - 110px);
}

.salesPipelineSmallTable {
  text-align: left;
}

.salesPipelineSmallTable th {
  font-weight: 500;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .tags-layout {
    margin-left: 0px;
  }
  .edit-tags-auto-table {
    max-width: 100%;
  }
  .edit-tags-auto-container {
    /* min-width: 400px; */
    width: 100% !important;
    max-width: 100%;
  }
  .kiosk-save {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
    margin-bottom: 23px;
  }
  .cancel_save_duo {
    width: 180px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1250px) {
  .tag-clients-number {
    padding-right: 30px;
  }

  .tags_row3 {
    width: 15% !important;
    margin-right: 1vw;
  }

  .tags_row4 {
    width: 15% !important;
    margin-right: 1vw;
  }
  .tags_row5 {
    width: 15% !important;
    margin-right: 2vw;
  }

  .tags_row6 {
    width: 15% !important;
    margin-right: 1vw;
  }

  .tags-row-color {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }

  .edit-tags-auto-container {
    min-width: 400px !important;
    max-width: 100% !important;
  }
  .kiosk-save div {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 478px) {
  .kiosk-save-button {
    margin-left: 0px;
    margin-bottom: 12px;
  }
  /* .kiosk-save div {
    max-width: 250px;
  } */
}

@media only screen and (max-width: 1518px) {
  .lg-table-sidebar {
    width: 100% !important;
    overflow-x: scroll;
    overflow-y: visible;
  }
}
