/* PAYMENTS BANNER */
.paymentBanner {
  height: 50px;
  background-color: #33aafc;
  top: 0px;
  left: 0px;
  position: fixed;
}

.pendingPaymentBanner {
  height: 50px;
  background-color: #1769af;
  top: 0px;
  left: 0px;
  position: fixed;
}

.bannerDisplayNone {
  display: block;
}

.bannerText {
  align-items: center;
  width: 100vw;
  position: absolute;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #ffffff;
}

@keyframes example {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(1.5px);
  }

  100% {
    transform: translateX(3px);
  }
}

.bannerArrowAnimation {
  margin-left: 8px;
  animation: example 0.2s linear 2s 6 alternate;
}
