.edit-image-icon {
  height: 104px;
  width: 104px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  object-fit: cover;
}

.profile_upload {
  position: absolute;
  opacity: 0;
  width: 105px;
  height: 105px;
  z-index: 11;
}

.upload_img_btn {
  background-color: #000;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 3px solid white;
  z-index: 10;
  position: absolute;
  margin-top: 75px;
  display: inline-block;
  margin-left: 33px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_upload_label {
  height: 35px;
  display: block;
  padding-top: 13px;
  z-index: 15;
}

.profile_upload_label.active {
  opacity: 0.5;
}

.profile_upload_div {
  background-color: #fff;
  z-index: 0;
  margin-right: 0px;
  float: left;
  position: relative;
}

button:focus {
  outline: 0;
}
