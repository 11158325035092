.ant-collapse-borderless {
  background-color: #fff;
  padding-bottom: 30px;
}

.ant-layout {
  background-color: #fff;
}
/* ////////////////////////////////////////////// */
/* /////// CHAT WINDOW COMPONENT [HEADER] /////  */
/* //////////////////////////////////////////// */

.conversation-window {
  display: flex;
  flex-direction: column;
}

.ant-page-header-heading-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.ant-page-header-heading-sub-title {
  font-size: 12px;
  font-weight: 400;
}

.ant-page-header-heading-left {
  flex-direction: column;
  align-items: flex-start !important;
}

.ant-page-header-heading-extra {
  color: #818181;
  align-self: center;
}

.conversation-window-layout {
  background-color: #fff;
  border-bottom: 0.5px solid #ebebeb;
}

.conversation-window-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.conversation-window-header-user-icon {
  display: flex;
  align-items: center;
  margin-block: 10px;
  margin-right: 8px;
  margin-left: 15px !important;
  width: 36px;
  height: 36px;
  z-index: 12;
}

.ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text {
  font-weight: 500;
  color: #818181;
  text-align: left;
}
.ant-collapse-item > .ant-collapse-header > .ant-collapse-expand-icon > .ant-collapse-arrow > svg {
  color: #818181;
  font-weight: 500;
  height: 16px;
  width: 16px;
}
.ant-collapse-item-active > .ant-collapse-header > .ant-collapse-header-text {
  color: black;
}
.ant-collapse-item-active > .ant-collapse-header > .ant-collapse-expand-icon > .ant-collapse-arrow > svg {
  color: black;
}

/* /////////////////////////////////////////////// */
/* /////// CHAT WINDOW COMPONENT [FOOTER] //////  */
/* ///////////////////////////////////////////// */
.ant-layout-footer {
  background-color: #fff;
  padding: 10px 10px;
  display: flex;
}

.ant-layout-footer > input {
  border-radius: 20px;
}

.conversation-window-footer-icons {
  padding: 5px;
  display: flex;
  align-items: center;
}

.conversation-window-footer-icons > span {
  padding: 5px;
}

.fakeChatInputBoxStyle {
  width: calc(100% - 30px);
  padding: 11px 10px 11px 0px;
  margin-left: 17px;
  max-height: 130px;
  transition: all 0.3s, height 0s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow: hidden;
  z-index: 20;
}

/* /////////////////////////////////////////////// */
/* /////// END CHAT WINDOW COMPONENT ////////////  */
/* ///////////////////////////////////////////// */

/* drawer styles */

.upcomingAutoMessage {
  width: 260px;
  height: 111px;
  background: #ffffff;
  box-shadow: 1px 2px 9px rgb(0 0 0 / 11%);
  border-radius: 6px;
  position: relative;
}
.upcomingAutoReminder {
  width: 260px;
  min-height: 90px;
  max-height: 120px;
  background: #ffffff;
  box-shadow: 1px 2px 9px rgb(0 0 0 / 11%);
  border-radius: 6px;
  position: relative;
}

.upcomingAutoMessageCardPadding {
  padding: 4px 8px;
}

.upcomingReminderStrip {
  background-color: red;
  width: 5px;
  height: 100%;
  position: absolute;
  border-radius: 6px 0px 0px 6px;
}

.paymentDivider {
  width: 260px;
  height: 0px;
  border: 0.5px solid #ebebeb;
  opacity: 0.5;
}

/* search styles */

.inboxSearch2 {
  border-radius: 3px;
  border: none;
  padding-top: 2px;
  padding-left: 43px;
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg");
  background-repeat: no-repeat;
  background-position: 17px 15px;
  outline: none;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 12px;
  color: #818181;
  width: 149px;
  display: block;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #818181;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 6px;
}

.ant-tabs-ink-bar {
  background: #bdbdbd;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0 0;
}

.inboxAvatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 15px;
  cursor: pointer;
}

.inboxAvatarLI {
  width: 100%;
  height: 68px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.inboxAvatarLI:hover {
  background: #f2f3f6;
}

.inboxIcons {
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
}

.inboxIcons:hover {
  background: #f2f3f6;
}

.inboxAvatarLILI:hover {
  background: #f2f3f6 !important;
}

.inboxAvatarInitials {
  padding-top: 4px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 15px;
  margin-bottom: 9px;
  margin-top: 8px;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChatMoreOptionDiv {
  position: absolute;
  width: 157px;
  z-index: 2000;
  background-color: white;
  right: 10px;
  border-radius: 4px;
  border: 1px solid #f2f3f6;
}

.inboxGrey {
  background-color: #f2f3f6;
}

.chatListSelect {
  background-color: #e8f4ff !important;
}
#chatListSelect {
  background-color: #e8f4ff !important;
}
#chatListUnselected {
  background-color: #fff;
}

#scrollableDivChat::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrollableDivChat {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#textAreaInputWithActionsInbox {
  --scrollbarBG: transparent;
  --thumbBG: #EBEBEB;
  ;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

#textAreaInputWithActionsInbox::-webkit-scrollbar {
  width: 8px;
}

#textAreaInputWithActionsInbox::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

#textAreaInputWithActionsInbox::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 5px;
  border: 3px solid var(--scrollbarBG);
}

#textAreaInputWithActions {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.clientSearchInbox {
  border: none;
  padding-top: 2px;
  padding-left: 43px;
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg");
  background-repeat: no-repeat;
  background-position: 17px 15px;
  outline: none;
  font-size: 14px;
}

.ant-list-bordered {
  border-radius: 4px;
  overflow-y: hidden;
}

.ant-list-bordered {
  height: 200px;
  overflow-y: scroll;
  border-radius: 10px;
}

.ant-list-bordered::-webkit-scrollbar {
  display: none;
}

.ant-list-bordered {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 28px;
}

.ant-list-item:hover {
  background: #e5f2fb !important;
}

.ant-popover-placement-bottomRight {
  left: 90px;
  top: 160px;
}

.ant-popover-content {
  left: 7px;
}

.clientInfoDrawerDiv {
  overflow-y: scroll;
  animation-duration: 0.3s;
  animation-name: slidein;
  border-left: 0.5px solid #ebebeb;
  padding: 15px 0px 30px 0px;
}

.clientInfoDrawerDiv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.clientInfoDrawerDiv {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.conversation-window-header-heading-sub {
  padding-left: 10px;
}

.ant-popover-inner {
  border-radius: 8px;
}

.assignMenu {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  border-radius: 4px;
}
/*
.ant-dropdown-menu-item:hover {
  border-radius: 4px;
} */

/* .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 0px;
  width: 157px;
  height: 43px;
} */

.headerAssignDiv {
  padding-top: 5px;
  padding-left: 3px;
  width: 30px;
  height: 30px;
  margin-right: 7px;
  margin-bottom: 3px;
  border-radius: 4px;
}
.headerAssignDiv:hover {
  background: #f2f3f6;
}
.headerAssignClick {
  background: #f2f3f6;
}

/* animations */
@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

/* styles only for small screens */
.inboxWithBanner {
  padding-left: 200px;
}
.inboxWithoutBanner {
  padding-left: 200px;
}
.clientDetailsWithBanner {
  padding-top: 107px;
}
.clientDetailsWithoutBanner {
  padding-top: 60px;
}
.conversationWindowHeightWithoutBanner {
  height: calc(100vh - 55px);
}
.conversationWindowHeightWithBanner {
  height: calc(100vh - 102px);
}
.scrollableDivChatWithBanner {
  height: calc(100vh - 300px);
}
.scrollableDivChatNoBanner {
  height: calc(100vh - 240px);
}
/* /////////////////////////////////////////////// */
/* /////////////// MEDIA QUERIES ////////////////  */
/* ///////////////////////////////////////////// */

@media only screen and (min-width: 1600px) {
  .conversation-window-header-heading-icons {
    margin-left: 70%;
  }
}
@media only screen and (max-width: 800px) {
  .inboxWithBanner {
    padding-left: 15px;
  }
  .inboxWithoutBanner {
    padding-left: 15px;
  }
  .conversationWindowHeightWithBanner {
    max-height: calc(100vh - 65px);
  }
  .scrollableDivChatWithBanner {
    height: calc(100vh - 240px);
  }
}
