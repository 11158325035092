.standardButton {
  width: 175px;
  height: 45px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  font-family: Poppins;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  /* color: #33aafc; */
}

.standardButton svg {
  margin-right: 6px;
  margin-bottom: -3px;
}

.transparentButton {
  color: #1769AF;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.clearButton {
  width: 280px;
  height: 32px;
  border: 1px solid #33AAFC;
  color: #33AAFC;
  border-radius: 20px;
  background-color: white;
  font-weight: 600;
  font-size: 13px;
  font-family: Poppins;
  line-height: 20px;
  cursor: pointer;
}

.darkBlueButton {
  background-color: #33AAFC;
  color: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  height: 32px;
  width: 280px;
  line-height: 20px;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 20px;
}

.darkBlueButton img {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
}

.cancelAssctBtn {
  background-color: #353538;
  color: #ffffff;
  font-family: Poppins;
  border: none;
  font-weight: 600;
  font-size: 13px;
}

.deleteButton {
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  color: #d93b3b;
  text-align: left;
  line-height: 30px;
  background-color: white;
  width: 100px;
}

.deleteButton svg {
  /* height: 20px; */
  vertical-align: middle;
  margin-right: 16px;
  margin-bottom: 10px;
}

.deleteButton-v2 {
  border: 1px solid #D93B3B;
  border-radius: 20px;
  width: 280px;
  height: 32px;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  color: #d93b3b;
  text-align: center;
  line-height: 30px;
  background-color: white;
}

@media only screen and (max-width: 1000px) {
  .standardButtonWidth {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .standardCancelSave {
    width: 48%;
  }
}
