.choose-file-div {
  align-self: center;
}

.choose-file-btn {
  width: 118px;
  height: 20px;
  /* margin: 0 0 20px;
  padding: 14px 11px 14px 12px; */
  display: flex;
  justify-self: center;
  justify-content: space-between;
  border-radius: 3px;
  background-color: #FAFAFA;
  border: none;
  color: #1769AF;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
