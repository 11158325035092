.new-pop-confirm {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-pop-confirm__card {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  width: 340px;
}

.new-pop-confirm__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
  margin-bottom: 16px;
}

.new-pop-confirm__description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
