.nav2Container {
  height: 57px;
  background-color: #231f20;
  /* top: 50px; */
  left: 0px;
}

.navDashboardText {
  height: 17px;
  margin: 20px 0px 8px 0px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #dedddd;
}
.navDashboardText:hover {
  color: #fff;
}

.navDropDown {
  height: 17px;
  margin: 20px 0px 8px 0px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #dedddd;
  cursor: pointer;
}

.navDropDown .ant-badge,
.SPlink .ant-badge {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #dedddd;
  cursor: pointer;
}

.navDropDown .ant-badge-dot {
  left: -12px;
  top: 9px;
  box-shadow: none;
}

.SPlink .ant-badge-dot {
  left: -16px;
  top: 9px;
  box-shadow: none;
}

.navDropDown:hover {
  color: #fff;
}
.angleHover {
  color: #dedddd;
}
.angleHover:hover {
  color: #ffffff;
  border-bottom: solid 2px #33aafc;
}

.angleHover_active {
  color: #ffffff;
}

.dropDown-nav {
  width: 169px;
  height: auto;
  padding: 0 0 0px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  background-color: #353535;
  position: absolute;
  top: 57px;
  display: block;
  left: -20px;
}

.dropDown-nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.dropDown-nav .mobile-scroll {
  margin-top: 0;
}

.dropDown-nav-item {
  color: #dedddd;
}
.dropDown-nav-item:hover {
  color: #ffffff;
}
.dropDown-nav-active {
  color: #ffffff !important;
}

.dropDown-nav li {
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  text-align: left;
  line-height: 4;
  list-style: none;
  /* padding: 0px 0px 3px 25px; */
  margin-left: -40px;
  height: 37px;
  width: calc(100% + 15px);
  width: 168px;
  color: #fff;
  margin-right: 0px;
  position: relative;
}

/* drop down styles for profile image dropdown */
.profileDropDown_nav {
  width: 169px;
  height: auto;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  background-color: #353535;
  position: absolute;
  top: 50px;
  display: block;
  right: 0px;
}

.profileDropDown_nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.profileDropDown_nav li {
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  text-align: left;
  line-height: 4;
  list-style: none;
  padding: 0px 0px 3px 25px;
  margin-left: -40px;
  height: 37px;
  width: calc(100% + 15px);
  color: #fff;
  position: relative;
}

.nav-background {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  /* background-color: hsla(0, 0%, 100%, 0.16); */
  z-index: 10;
}

.SPlink {
  width: 100px;
}

.SPlink:hover {
  background-color: #616161;
}

.userProfileSmallScreen {
  display: none;
}

/* multi nav */

.multiNavHeader_div {
  width: 131px;
  font-size: 13px;
  opacity: 1;
  font-weight: 500;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  list-style: none;
  margin-bottom: 10px;
}
.multiNavHeader_div ul {
  list-style-type: none;
}

.multiNavHeader_p {
  display: inline-block;
  width: 145px;
  color: #495155;
  font-size: 11px;
  opacity: 1;
  font-weight: 600;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  list-style: none;
  margin: 3px 30px 5px 10px;
  padding: 5px 0px 5px 5px;
}

.MN_Header_Active {
  color: #ffffff !important;
  height: 20px;
  border-radius: 3px;
  background-color: #616161;
}

/* overhead (MAIN) title */

.overhead_title {
  display: inline-block;
  width: 145px;
  color: #000000;
  font-size: 12px;
  opacity: 1;
  font-weight: 600;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  list-style: none;
  margin: 15px 30px 5px 10px;
  padding: 5px 0px 5px 5px;
}

/* PAYMENTS BANNER */

.paymentOn {
  top: 50px;
}
.paymentOff {
  top: 0px;
}

@media only screen and (max-width: 800px) {
  .profileDropDown_nav {
    display: none;
  }

  .userProfileSmallScreen {
    display: block;
  }
}
