.checkbox-container {
  display: flex;
}

/* .checkbox-container button {
  border: none;
  height: 25px;
  width: 25px;
  margin-top: 27px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
} */

.checkbox_container_button {
  border: none;
  height: 25px;
  width: 25px;
  /* margin-top: 27px; */
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
}

.checkbox {
  width: 20px;
  height: 20px;
}

.checkbox-text {
  font-size: 14px;
  font-weight: 500;
  margin-left: 0px;
}
