.cb-client-card {
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}

.cb-client-card__content h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin: 0;
}

.cb-client-card__content span {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #818181;
}
