/* stylelint-disable selector-class-pattern */
.cb-integrations-pop-confirm__link {
  color: #33aafc;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-decoration-line: underline;
}

.cb-integrations-pop-confirm__link__disabled {
  color: gray;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  cursor: default;
  pointer-events: none;
}

