.bulleted-list-title {
  text-align: left;
  font-size: 10px;
  color: #818181;
  margin-bottom: 10px;
  max-width: 400px;
}

.bulleted-list-list {
  text-align: left;
  font-size: 10px;
  color: #818181;
  max-width: 400px;
}