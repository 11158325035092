.products-v2-details {
  flex: 1;
  padding: 20px;
  text-align: left;
  overflow-y: auto;

  &__details {
    display: flex;
    justify-content: space-between;

    &-left,
    &-right {
      display: flex;
      align-items: center;
    }

    &-price {
      font-weight: 600;
      font-size: 16px;
      margin-right: 16px;
    }

    &-ref {
      font-size: 14px;
      color: #737373;
    }

    &-pos-ref-num {
      font-size: 14px;
      color: #737373;
      margin-right: 16px;
    }

    &-available {
      display: flex;
      align-items: center;
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    &-in-stock {
      font-size: 30px;
      color: #52c41a;
      margin-right: 6px;
    }

    &-out-of-stock {
      font-size: 30px;
      color: #faad14;
      margin-right: 6px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 12px;
  }
}
