.cb-pagination-v2 .ant-pagination {
  display: flex;
  align-items: center;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-item {
  margin: 8px !important;
  border: 2px solid #33aafc;
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-item a {
  padding: 0;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-item-active {
  font-weight: bold;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-prev,
.cb-pagination-v2 .ant-pagination .ant-pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cb-pagination-v2 .ant-pagination .anticon-caret-left,
.cb-pagination-v2 .ant-pagination .anticon-caret-right {
  color: #bdbdbd;
}

.cb-pagination-v2 .ant-pagination .anticon-caret-left svg,
.cb-pagination-v2 .ant-pagination .anticon-caret-right svg {
  width: 16px;
  height: 16px;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-options {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-options > .ant-select {
  display: flex;
}

.cb-pagination-v2 .ant-pagination .ant-select .ant-select-selector,
.cb-pagination-v2 .ant-pagination .ant-pagination-options-quick-jumper input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px !important;
  border: 1px solid #818181;
  border-radius: 4px;
}

.cb-pagination-v2 .ant-pagination .ant-pagination-options-quick-jumper {
  display: flex;
  align-items: center;
  justify-content: center;
}
