div.product-details-layout__container {
  max-width: 1024;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 16px;
}

@media only screen and (max-width: 800px) {
  div.product-details-layout__container {
    display: flex;
    flex-direction: column;
  }
}
