/**** General Layout Component CSS ****/

#textBlock {
  display: none;
}

#textBlock_active {
  display: block;
}

.divider {
  width: 866px;
  height: 1px;
  border-top: solid 0.5px #979797;
  margin-bottom: 40px;
}

.w-100-P-divider {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px #979797;
}
.inputHeight {
  height: 50px;
}

.boxShadow {
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.107252);
}

.no-input-arrows::-webkit-outer-spin-button,
.no-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.no-input-arrows[type='number'] {
  -moz-appearance: textfield;
}

.tags-checkmark {
  width: 36px;
  height: 24px;
  padding-left: 30px;
}

.darkBlueButton:disabled {
  background-color: #818181;
  cursor: not-allowed;
}

.common_inputStyle:disabled {
  color: black;
  background-color: #f3f3f3;
  border: none;
}

.common_dropdownStyle {
  outline: none;
  border-radius: 3px;
  border: solid 1px #818181;
}
.common_dropdownStyle:focus {
  outline: 2px solid #33aafc;
}

.common_inputStyle:focus {
  outline: 2px solid #33aafc;
}

.focus-input-outline-blue:focus {
  outline: 2px solid #33aafc;
}
.focus-input-outline-blue-without-border:focus {
  border: 0;
}

.border-missing-data-state {
  border: 1px solid #ff7a7a !important;
}

.missing-data-state {
  outline: 1px solid #ff7a7a;
  background-color: #f9e0e0;
}

.common_inputStyle {
  padding-left: 18px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #818181;
  outline: none;
  /* I added these so that the width doesn't expand when we use that padding-left there */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.searchInput {
  border-radius: 3px;
  font-size: 16px;
  border: none;
  width: 330px;
  height: 60px;
  padding-top: 2px;
  padding-left: 60px;
  background-image: url('https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg');
  background-repeat: no-repeat;
  background-position: 20px 18px;
  outline: none;
}
.searchInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
  opacity: 1; /* Firefox */
}

.ff-poppins {
  font-family: Poppins, sans-serif;
}

.searchInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9c9c9c;
  font-size: 13px;
}

.searchInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9c9c9c;
  font-size: 13px;
}
/* BASIC POSITIONING */

.input-image-style {
  background-repeat: no-repeat;
  background-position: 15px 17px;
  outline: none;
  border: 1px solid rgb(129, 129, 129);
  height: 56px;
  border-radius: 3px;
  padding-top: 2px;
  padding-left: 45px;
}

.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.pos-fixed {
  position: fixed;
}

.pos-sticky {
  position: sticky;
}

.right-0 {
  right: 0;
}

.right-8 {
  right: 8px;
}

.left-0 {
  left: 0;
}
.bottom-0 {
  bottom: 0;
}
.bottom-12 {
  bottom: 12px;
}

.top-0 {
  top: 0;
}
.align-vert-middle {
  display: table-cell;
  vertical-align: middle;
}
.align-text-left {
  text-align: left;
}
.align-left {
  text-align: left;
  align-content: left;
}
.align-center {
  text-align: center !important;
  align-content: center;
}
.align-right {
  text-align: right !important;
  align-content: right;
}
.align-bottom {
  bottom: 0;
}
.align-top {
  top: 0;
}
.float-top {
  float: top;
}

/* COMMON MARGINS */
.margin-0 {
  margin: 0;
}
.m-btm-0 {
  margin-bottom: 0px;
}
.m-btm-5 {
  margin-bottom: 5px;
}
.m-btm-6 {
  margin-bottom: 6px;
}
.m-btm-8 {
  margin-bottom: 8px;
}
.m-btm-10 {
  margin-bottom: 10px;
}
.m-btm-11 {
  margin-bottom: 11px;
}
.m-btm-12 {
  margin-bottom: 12px;
}
.m-btm-15 {
  margin-bottom: 15px;
}
.m-btm-16 {
  margin-bottom: 16px;
}
.m-btm-20 {
  margin-bottom: 20px;
}
.m-btm-24 {
  margin-bottom: 24px;
}
.m-btm-25 {
  margin-bottom: 25px;
}
.m-btm-28 {
  margin-bottom: 28px;
}
.m-btm-35 {
  margin-bottom: 35px;
}
.m-btm-41 {
  margin-bottom: 41px;
}
.m-btm-56 {
  margin-bottom: 56px;
}
.m-top--15 {
  margin-top: -15px;
}
.m-top-0 {
  margin-top: 0;
}
.m-top-4 {
  margin-top: 4px;
}
.m-top-3 {
  margin-top: 3px;
}
.m-top-5 {
  margin-top: 5px;
}
.m-top-6 {
  margin-top: 5px;
}
.m-top-7 {
  margin-top: 7px;
}
.m-top-8 {
  margin-top: 8px;
}
.m-top-10 {
  margin-top: 10px;
}
.m-top-11 {
  margin-top: 11px;
}
.m-top-12 {
  margin-top: 12px;
}
.m-top-13 {
  margin-top: 13px;
}
.m-top-15 {
  margin-top: 15px;
}
.m-top-16 {
  margin-top: 16px;
}
.m-top-18 {
  margin-top: 18px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-top-24 {
  margin-top: 24px;
}
.m-top-25 {
  margin-top: 25px;
}
.m-top-28 {
  margin-top: 28px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-top-35 {
  margin-top: 35px;
}
.m-top-37 {
  margin-top: 37px;
}
.m-top-40 {
  margin-top: 40px;
}
.m-top-44 {
  margin-top: 44px;
}
.m-top-45 {
  margin-top: 45px;
}
.m-top-50 {
  margin-top: 50px;
}
.m-top-60 {
  margin-top: 60px;
}
.m-top-75 {
  margin-top: 75px;
}

.m-x-15 {
  margin: 0 15px;
}

.m-x-12 {
  margin: 0 12px;
}

.m-right-0 {
  margin-right: 0;
}

.m-right-4 {
  margin-right: 4px;
}
.m-right-5 {
  margin-right: 5px;
}
.m-right-6 {
  margin-right: 6px;
}
.m-right-7 {
  margin-right: 7px;
}
.m-right-8 {
  margin-right: 8px;
}
.m-right-9 {
  margin-right: 9px;
}
.m-right-10 {
  margin-right: 10px;
}
.m-right-11 {
  margin-right: 11px;
}
.m-right-12 {
  margin-right: 12px;
}
.m-right-13 {
  margin-right: 13px;
}
.m-right-14 {
  margin-right: 14px;
}
.m-right-15 {
  margin-right: 15px;
}
.m-right-18 {
  margin-right: 18px;
}
.m-right-20 {
  margin-right: 20px;
}
.m-right-25 {
  margin-right: 25px;
}
.m-right-24 {
  margin-right: 24px;
}
.m-right-30 {
  margin-right: 30px;
}
.m-right-35 {
  margin-right: 35px;
}
.m-right-60 {
  margin-right: 60px;
}
.m-left-29 {
  margin-left: 29px;
}
.m-left-4 {
  margin-left: 4px;
}
.m-left-0 {
  margin-left: 0px;
}
.m-left-7 {
  margin-left: 7px;
}
.m-left-8 {
  margin-left: 8px;
}
.m-left-10 {
  margin-left: 10px;
}
.m-left-12 {
  margin-left: 12px;
}
.m-left-13 {
  margin-left: 13px;
}
.m-left-15 {
  margin-left: 15px;
}
.m-left-17 {
  margin-left: 17px;
}
.m-left-18 {
  margin-left: 18px;
}
.m-left-21 {
  margin-left: 21px;
}
.m-left-23 {
  margin-left: 23px;
}
.m-left-2em {
  margin-left: 2em;
}
.m-left-5vw {
  margin-left: 5vw !important;
}
.padding-0 {
  padding: 0;
}
.p-4 {
  padding: 4px;
}
.padding-20 {
  padding: 20px;
}
.p-10 {
  padding: 10px;
}
.p-16 {
  padding: 16px;
}
.p-12 {
  padding: 12px;
}
.p-16 {
  padding: 16px;
}
.p-25 {
  padding: 25px;
}
.p-42 {
  padding: 42px;
}
.p-50 {
  padding: 50px;
}
.p-60 {
  padding: 60px;
}
.p-right-0 {
  padding-right: 0;
}
.p-right-6 {
  padding-right: 6px;
}
.p-right-8 {
  padding-right: 8px;
}
.p-right-10 {
  padding-right: 10px;
}
.p-right-15 {
  padding-right: 15px;
}
.p-right-21 {
  padding-right: 21px;
}
.p-right-25 {
  padding-right: 25px;
}
.p-right-30 {
  padding-right: 30px;
}
.p-left-0 {
  padding-left: 0px;
}
.p-left-6 {
  padding-left: 6px;
}
.p-left-8 {
  padding-left: 8px;
}
.p-left-10 {
  padding-left: 10px;
}

.p-left-15 {
  padding-left: 15px;
}
.p-left-16 {
  padding-left: 16px;
}
.p-left-17 {
  padding-left: 17px;
}
.p-left-18 {
  padding-left: 18px;
}
.p-left-20 {
  padding-left: 20px;
}
.p-left-21 {
  padding-left: 21px;
}
.p-left-24 {
  padding-left: 24px;
}
.p-left-26 {
  padding-left: 26px;
}
.p-left-30 {
  padding-left: 30px;
}
.p-left-30-imp {
  padding-left: 30px !important;
}
.p-left-40 {
  padding-left: 40px;
}
.p-left-41 {
  padding-left: 41px;
}
.p-left-44 {
  padding-left: 44px;
}
.p-left-60 {
  padding-left: 60px;
}
.p-left-200 {
  padding-left: 200px;
}
.p-right-33 {
  padding-right: 33px;
}
.p-right-26 {
  padding-right: 26px;
}
.p-right-40 {
  padding-right: 40px;
}
.p-right-41 {
  padding-right: 41px;
}
.p-right-44 {
  padding-right: 44px;
}
.p-right-50 {
  padding-right: 50px;
}
.p-btm-3 {
  padding-bottom: 3px;
}
.p-btm-4 {
  padding-bottom: 4px;
  padding-bottom: 4px;
}
.p-btm-6 {
  padding-bottom: 6px !important;
}
.p-btm-7 {
  padding-bottom: 7px;
}
.p-btm-10 {
  padding-bottom: 10px;
}
.p-btm-11 {
  padding-bottom: 11px;
}
.p-btm-13 {
  padding-bottom: 13px;
}
.p-btm-15 {
  padding-bottom: 15px;
}
.p-btm-16 {
  padding-bottom: 16px;
}
.p-btm-20 {
  padding-bottom: 20px;
}
.p-btm-26 {
  padding-bottom: 26px;
}
.p-btm-48 {
  padding-bottom: 48px;
}


.p-top-3 {
  padding-top: 3px;
}
.p-top-4 {
  padding-top: 4px;
}
.p-top-6 {
  padding-top: 6px;
}
.p-top-7 {
  padding-top: 7px;
}
.p-top-8 {
  padding-top: 8px;
}
.p-top-10 {
  padding-top: 10px;
}
.p-top-11 {
  padding-top: 11px;
}
.p-top-12 {
  padding-top: 12px;
}
.p-top-14 {
  padding-top: 14px;
}
.p-top-15 {
  padding-top: 15px;
}
.p-top-16 {
  padding-top: 16px;
}
.p-top-20 {
  padding-top: 20px;
}
.p-top-26 {
  padding-top: 26px;
}
.p-top-36 {
  padding-top: 36px;
}
.p-top-60 {
  padding-top: 60px;
}
.p-top-125 {
  padding-top: 125px;
}

.p-x-24 {
  padding: 0 24px;
}
.chatBubblePadding {
  padding: 12px 24px 12px 24px;
}
.ul-no-style {
  padding: 0px;
  list-style: none;
  margin: 0px;
}
/* COMMON HEIGHT */

.h-100-P {
  height: 100%;
}
.h-50-P {
  height: 50%;
}

.h-95-P {
  height: 95%;
}

.height-auto {
  height: auto !important;
}
.input-height {
  height: 50px;
}
.h-1 {
  height: 1px;
}
.h-4 {
  height: 4px;
}
.h-10 {
  height: 10px;
}
.h-12 {
  height: 12px;
}
.h-20 {
  height: 20px;
}
.h-22 {
  height: 22px;
}
.h-23 {
  height: 23px;
}
.h-24 {
  height: 24px;
}
.h-25 {
  height: 25px;
}
.h-26 {
  height: 26px;
}
.h-28 {
  height: 28px;
}
.h-29 {
  height: 29px;
}
.h-33 {
  height: 33px;
}
.h-36{
  height: 36px;
}

.h-35 {
  height: 35px;
}
.h-38 {
  height: 38px;
}
.h-40 {
  height: 40px;
}
.h-45 {
  height: 45px;
}
.h-46 {
  height: 46px;
}
.h-47 {
  height: 47px;
}
.h-48 {
  height: 48px;
}
.h-50 {
  height: 50px;
}
.h-54 {
  height: 54px;
}
.h-57 {
  height: 57px;
}
.h-64 {
  height: 64px;
}
.h-67 {
  height: 67px;
}
.h-80 {
  height: 80px;
}
.h-83 {
  height: 83px;
}
.h-108 {
  height: 108px;
}
.h-167 {
  height: 167px;
}
.h-173 {
  height: 173px;
}
.h-174 {
  height: 174px;
}
.h-194 {
  height: 194px;
}
.h-200 {
  height: 200px;
}
.h-218 {
  height: 218px;
}
.h-247 {
  height: 247px;
}
.h-263 {
  height: 263px;
}
.h-292 {
  height: 292px;
}
.h-377 {
  height: 377px;
}
.h-402 {
  height: 402px;
}
.h-460 {
  height: 460px;
}
.h-546 {
  height: 546px;
}
.h-565 {
  height: 565px;
}
.h-665 {
  height: 665px;
}
.max-h-195 {
  max-height: 195px;
}
.max-h-603 {
  max-height: 603px;
}
.max-h-893 {
  max-height: 893px;
}
.max-h-100vh {
  max-height: 100vh;
}
.h-100-vh {
  height: 100vh;
}
/* COMMON WIDTHS */

.w-20-vw {
  width: 20vw;
}

.w-50-vw {
  width: 20vw;
}

.w-100-vw {
  width: 100vw;
}

.w-48-P {
  width: 48%;
}

.w-50-P {
  width: 50%;
}

.w-30-P {
  width: 30%;
}

.w-90-P {
  width: 90%;
}

.w-95-P {
  width: 95%;
}

.w-100-P {
  width: 100%;
}

.width-auto {
  width: auto;
}
.width-fit {
  width: fit-content;
}

/* COMMON INPUT WIDTHS - but not limited to Inputs */
.resize-none {
  resize: none;
}
.w-50vw-175px-to-100-P {
  width: calc(50vw - 175px);
}
.w-100-P-250px {
  width: calc(100% - 350px) !important;
}
.mih-218 {
  min-height: 218px;
}
.miw-100 {
  min-width: 100px;
}
.mw-150 {
  min-width: 150px;
}
.miw-250 {
  min-width: 250px;
}
.w-1 {
  width: 1px;
}
.w-8 {
  width: 8px;
}
.w-10 {
  width: 10px;
}
.w-12 {
  width: 12px;
}
.w-20 {
  width: 20px;
}
.w-26 {
  width: 26px;
}
.w-29 {
  width: 29px;
}
.w-33 {
  width: 33px;
}
.w-35 {
  width: 35px;
}
.w-38 {
  width: 38px;
}
.w-40 {
  width: 40px;
}
.w-41 {
  width: 41px;
}
.w-46 {
  width: 46px;
}
.w-48 {
  width: 48px;
}
.w-50 {
  width: 50px;
}
.w-67 {
  width: 67px;
}
.w-72 {
  width: 72px;
}
.w-82 {
  width: 82px;
}
.w-100 {
  width: 100px;
}
.w-100-important {
  width: 100px !important;
}
.w-108 {
  width: 108px;
}
.w-140 {
  width: 140px;
}
.w-142 {
  width: 142px;
}
.w-150 {
  width: 150px;
}
.w-150-important {
  width: 150px !important;
}
.w-160 {
  width: 160px;
}
.w-167 {
  width: 167px;
}
.w-174 {
  width: 174px;
}
.w-187 {
  width: 187px;
}
.w-194 {
  width: 194px;
}
.w-200 {
  width: 200px;
}
.w-222 {
  width: 222px;
}
.w-230 {
  width: 230px;
}
.miw-240 {
  min-width: 240px;
}
.w-267 {
  width: 267px;
}
.mw-267 {
  min-width: 267px;
}
.w-282 {
  width: 282px;
}
.w-289 {
  width: 289px;
}
.w-290 {
  width: 290px;
}
.w-295 {
  width: 295px;
}
.w-296 {
  width: 296px;
}
.w-280-to-100-P {
  width: 280px;
}
.mw-280 {
  min-width: 280px;
}
.w-300 {
  width: 300px;
}
.w-320 {
  width: 320px;
}
.w-333 {
  width: 333px;
}
.mw-350 {
  min-width: 350px;
}
.w-356 {
  width: 356px;
}

.w-359 {
  width: 359px;
}

.w-365 {
  width: 365px;
}
.w-368 {
  width: 368px;
}
.w-394 {
  width: 394px;
}

.w-402 {
  width: 402px;
}

/* width of input in custom actions, probably tags and other configurations  */
.w-440 {
  width: 440px;
}
.w-486 {
  width: 486px;
}
.w-500 {
  width: 500px;
}

.w-510 {
  width: 510px;
}

.w-554 {
  width: 554px;
}

.w-590 {
  width: 590px;
}

.w-600 {
  width: 600px;
}

.max-w-249 {
  max-width: 249px;
}
.max-w-404 {
  max-width: 404px;
}
.max-w-438 {
  max-width: 438px;
}
.max-w-554 {
  max-width: 554px;
}
.max-w-560 {
  max-width: 560px;
}
.max-w-600 {
  max-width: 600px;
}

.max-w-684 {
  max-width: 684px;
}
.max-w-800 {
  max-width: 800px;
}
.w-864 {
  width: 864px;
}
.w-866 {
  width: 866px;
}
.w-990 {
  width: 990px;
}
.maw-50 {
  max-width: 50px;
}
.maw-235 {
  max-width: 235px;
}
.maw-247 {
  max-width: 247px;
}
.maw-340 {
  max-width: 340px;
}
.maw-365 {
  max-width: 365px;
}
.maw-554 {
  max-width: 554px;
}
.maw-590 {
  max-width: 590px;
}
.maw-600 {
  max-width: 600px;
}
.maw-735 {
  max-width: 735px;
}
.maw-866 {
  max-width: 866px;
}
.maw-965 {
  max-width: 965px;
}
.maw-1100 {
  max-width: 1100px;
}
.maw-1200 {
  max-width: 1200px;
}
.maw-50-P {
  max-width: 50%;
}
.maw-96-P {
  max-width: 96%;
}
.maw-98-P {
  max-width: 98%;
}
.maw-100-P {
  max-width: 100%;
}

/* COMMON BUTTON WIDTHS */

.w-212 {
  width: 212px;
}
.w-227 {
  width: 227px;
}

/* COMMON SPAN FONT SIZE*/
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-16-to-14 {
  font-size: 16px;
}
.fs-14-to-12 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}

.fs-33 {
  font-size: 33px;
}

.fs-42 {
  font-size: 42px;
}
.fs-46 {
  font-size: 46px;
}
.fs-48 {
  font-size: 48px;
}
.fs-56 {
  font-size: 56px;
}
.fs-80 {
  font-size: 80px;
}
.fs-100 {
  font-size: 100px;
}

.italic {
  font-style: italic;
}

.text-clickable {
  cursor: pointer;
  text-decoration: underline;
  color: #1677FF;
  font-size: 14px;
}

.text-clickable-disable {
  cursor: not-allowed;
  text-decoration: underline;
  color: #7C7C7C;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}
.default-cursor {
  cursor: default;
}

.highlight-selected {
  background-color: #e8f6ff;
}
.not-allowed {
  cursor: not-allowed !important;
}

/* PHOTO QUALITY*/
.photoFit {
  object-fit: cover;
}

/* FONT / TEXT PROPERTIES */
.black {
  color: black;
}
.darkest-gray {
  color: #353538;
}
.matterhorn-gray {
  color: #565656;
}
.dark-gray {
  color: #7d7d7d;
}
.green {
  color: #7fbd31;
}
.gray {
  color: #818181;
}
.first-child-gray:nth-child(1):nth-child(2) {
  /* color: #818181 !important; */
}
.mid-gray {
  color: #9d9d9d;
}
.color-bdbdbd {
  color: #bdbdbd;
}
.light-gray {
  color: #d9d9d9;
}
.blue {
  color: var(--blue);
}
.blue-link {
  color: #007bd0;
}
.CBdarkBlue {
  color: #1769af;
}
.red {
  color: var(--red);
}
.missing-data-red {
  color: #ff7a7a;
}
.white {
  color: white;
}
.white-faded {
  color: rgba(255, 255, 255, 0.711);
}
.text-light-gray {
  color: #bdbdbd;
}
.transparent {
  background-color: transparent;
}
.transparent-important {
  background-color: transparent !important;
}
.bg-black-faded {
  background-color: rgba(0, 0, 0, 0.16);
}
.bg-red {
  background-color: var(--red);
}
.bg-white {
  background-color: white;
}
.bg-offwhite {
  background-color: #f4f4f4;
}
.bg-ultra-light-blue {
  background-color: #e8f4ff;
}
.bg-lightgray {
  background-color: #d9d9d9;
}
.bg-medgray {
  background-color: #d8d8d8;
}
.bg-gray {
  background-color: #cccbcb;
}
.bg-lightblue {
  background-color: var(--blue);
}
.bg-hover-lightblue:hover {
  background-color: var(--blue);
}
.bg-hover-e8f6ff:hover {
  background-color: #e8f6ff;
}
.bg-darkgray {
  background-color: #353538;
}
.bg-black {
  background-color: black;
}
.bg-orange {
  background-color: #f7b500;
}
.bg-green {
  background-color: #7fbd31;
}
.bg-red {
  background-color: #ff7a7a;
}
.bg-arrowGray {
  background-color: #bec8cc;
}
.bg-blur {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.first-child-fw-500:nth-child(1):nth-child(2) {
  /* font-weight: 500 !important; */
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.no-decoration {
  text-decoration: none;
}
.no-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.quote::after {
  content: '"';
}

.quote::before {
  content: '"';
}

/* COMMON DISPLAY  */

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-table-cell {
  display: table-cell;
}
.d-grid {
  display: grid;
}

.two-column-parent-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
  /* max-height: calc(100vh - 125px); */
}

.three-column-parent-grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  overflow-x: hidden;
}

/* .three-column-parent-grid::-webkit-scrollbar {
  display: none;
}
.three-column-parent-grid {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
.three-column-parent-2 {
  grid-template-columns: 300px auto;
}
.three-column-parent-3 {
  grid-template-columns: 300px 1fr auto;
}
.three-column-parent-3-client {
  grid-template-columns: 315px 1fr auto;
}

.react-datepicker__time-container--with-today-button {
  right: -87px !important;
  top: -1px !important;
}

.d-grid-stack {
  grid-column: 1;
  grid-row: 1;
}
.outline-none {
  outline: none;
}
.no-wrap {
  white-space: nowrap !important;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.underline {
  text-decoration: underline;
}
/* COMMON DISPLAY FLEX */

.test {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}

.flex-col-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.flex-col-left-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  align-content: stretch;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
.flex-col-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: stretch;
}
.flex-col-center-start {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.ant-tag-clientbook {
  background-color: #EAF4FE;
  color: #1769AF;
  border-radius: 4px;
  padding: 1px 6px;
  font-weight: 500;
}

.ant-segmented-item-label {
  padding: 0 !important;
}

.textarea-w-actions:empty:not(:focus):before{
  content:attr(data-ph);
  color: #BDBDBD;
}

.flex-row-rev-left {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}
.flex-row-rev-center {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-content: stretch;
}
.flex-row-rev-right {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-content: stretch;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex-row-spacebetween-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-around;
}

.flex-row-nospacebetween-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: space-around;
}
.flex-row-nospacebetween-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
}

.flex-row-spacebetween-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  gap: 12px;
}

.flex-row-spacearound-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: space-around;
}

.flex-row-spaceevenly-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: space-evenly;
}

.flex-row-spaceevenly-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: space-evenly;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-baseline-center {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}
.flex-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-row-spacebetween-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-spacebetween-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-row-end-start {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.stacking-inputs {
  max-width: 100%;
}

.add-edit-wrap {
  display: flex;
}

/* Borders */

.border-r3 {
  border-radius: 3px;
}
.border-r4 {
  border-radius: 4px;
}
.border-r6 {
  border-radius: 6px;
}
.border-r8 {
  border-radius: 8px;
}
.border-r10 {
  border-radius: 10px;
}
.border-r12 {
  border-radius: 12px;
}
.border-r14 {
  border-radius: 14px;
}
.border-31 {
  border-radius: 31px;
}
.border-r4-red {
  border-radius: 4px;
  border: solid 1px #d93b3b;
}
.border-r8-grey {
  border-radius: 8px;
  border: solid 1px #979797;
}
.border-20-light-grey {
  border-radius: 20px;
  border: 1px solid #bdbdbd;
}
.border-circle {
  border-radius: 50%;
  border: solid 0.5px black;
}

.border-top-1 {
  border-top: 1px solid #ebebeb;
}
.border-btm-1 {
  border-bottom: 1px solid #ebebeb;
}

.circle {
  border-radius: 50%;
}
.border-none {
  border: none;
}
.border-all {
  border: solid 1px #979797;
}
.border-btm {
  border-bottom: solid 1px #979797;
}
.border-left {
  border-left: solid 1px #979797;
}
.border-btm-l-gray {
  border-bottom: solid 1px #d0d0d0;
}
.border-right {
  border-right: solid 1px #979797;
}
/* Remove sizing added from padding increase */

.rem-pad-width {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

/* Table Reusable */

.col-p-l-25 {
  padding-left: 25px;
}
.col-p-r-25 {
  padding-right: 40px;
}
.col-p-r-70 {
  padding-right: 70px;
}

/* Responsive display */

.display-under-800 {
  display: none;
}

.display-under-1100 {
  display: none;
}

.overflowX {
  overflow-x: scroll;
}
.overflowY {
  overflow-y: scroll;
}

.line-clamp-1 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scrollbar-y{
  overflow-y: auto;
}

.scrollbar-y::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}
.scrollbar-y::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #F2F3F6;
}
.scrollbar-y::-webkit-scrollbar-track:hover {
  background-color: #EDEEF1;
}

.scrollbar-y::-webkit-scrollbar-track:active {
  background-color: #EAEBEE;
}

.scrollbar-y::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #BDBDBD;
}
.scrollbar-y::-webkit-scrollbar-thumb:hover {
  background-color: #B8B8B8;
}
.scrollbar-y::-webkit-scrollbar-thumb:active {
  background-color: #A8A8A8;
}

.overflowY-hidden {
  overflow-y: hidden;
}
.overflowX-hidden {
  overflow-x: hidden;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* export button */
.exportButton {
  z-index: 100;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-998 {
  z-index: 998;
}
.z-index-999 {
  z-index: 999;
}
.z-index-1001 {
  z-index: 1001;
}

.cancel-button {
  width: 175px;
  height: 45px;
  font-weight: 600;
  font-size: 13px;
  color: #1769af;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #1769af;
  cursor: pointer;
}

.save-button {
  width: 175px;
  height: 45px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  border-radius: 3px;
  background-color: #33AAFC;
  border: none;
  cursor: pointer;
}

.save-button-v2 {
  width: 280px;
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  /* background-color: #1769af; */
  background-color: #33AAFC;
  border: none;
  cursor: pointer;
}

.save-button-v2:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cancel-button-v2 {
  width: 280px;
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  color: #1769af;
  border-radius: 20px;
  background-color: #ffffff;
  /* border: solid 1px #1769af; */
  border: none;
  cursor: pointer;
}

.link-button {
  border: none;
  width: auto;
  white-space: nowrap;
  cursor: pointer;
  height: 20px;
  color: #33aafc;
  background: none;
  font-weight: 400;
  font-size: 12px;
}

.secondary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: transparent;
  color: #1769AF;
  font-weight: 600;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  border: 0
}

.opacity-50 {
  opacity: 0.5;
}

.secondary-button:disabled {
  opacity: 0.5;
}

.error-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: transparent;
  color: #FF7A7A;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  border: 0
}

.error-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.client-input {
  border: 0;
  border-bottom: 1px solid #979797;
  background-color: transparent;
}

.client-input::placeholder {
  color: #BDBDBD;
}

.no-text-wrap {
  white-space: nowrap;
}

.save-button:disabled {
  background-color: #818181;
  cursor: not-allowed;
}

.disabled {
  background-color: #818181;
  cursor: not-allowed;
}

.cursor-disabled {
  cursor: not-allowed;
}

.save-button img {
  margin-right: 5px;
}

/* Date Inputs */

/* .unstyledDate {
  -webkit-appearance: none;
} */
.unstyledDate::-webkit-inner-spin-button,
.unstyledDate::-webkit-calendar-picker-indicator {
  /* display: none; */
  -webkit-appearance: none;
}

/* international phone styles */

.react-tel-input .country-list .country.highlight {
  background-color: #33aafc !important;
}

.PhoneInput {
  width: 363px;
  height: 50px;
  border: 1px solid rgb(129, 129, 129);
  border-radius: 3px;
  /* background-color: #33aafc !important; */
}

.PhoneInputCountryFlag-borderColor {
  border-radius: 3px;
  border: solid 1px;
}

.PhoneInputCountry {
  border-right: 1px solid rgb(184, 184, 184);
  margin: 7px 5px 7px 0px;
}

.PhoneInputCountryIcon,
.PhoneInputCountryIcon--border {
  margin-left: 10px;
}

.PhoneInputCountrySelectArrow {
  margin-right: 10px;
  opacity: 1 !important;
  color: rgb(184, 184, 184) !important;
  border-bottom: solid 1px rgb(184, 184, 184);
  border-right: solid 1px rgb(184, 184, 184);
}

.PhoneInputInput {
  border: none;
  outline: none;
}

.topNavV2 {
  background-color: #F5F5F5;
  padding-left: 24px;
  padding-right: 16px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

/* Media Queries */

@media only screen and (max-width: 440px) {
  .mq-w-174 {
    width: 174px !important;
  }
}

@media only screen and (max-width: 450px) {
  .divider {
    width: 400px;
  }

  .w-394 {
    width: 350px;
  }

  .w-440 {
    width: 300px;
  }
  .mq-m-left-21 {
    margin-left: 0px;
  }
  .w-864 {
    width: 350px;
  }
  .common_inputStyle {
    width: 98%;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .w-50vw-175px-to-100-P {
    width: calc(100vw - 41px) !important;
  }
}
@media only screen and (max-width: 535px) {
  .mq-50-P-to-100-P-on-535 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 800px) {
  .w-50vw-175px-to-100-P {
    width: calc(50vw - 30px);
  }
  .fs-16-to-14 {
    font-size: 14px;
  }
  .fs-14-to-12 {
    font-size: 12px;
  }
  .divider {
    width: 450px;
  }
  #client-deetz-right {
    display: none;
  }
  #client-deetz-left {
    max-width: 100%;
  }
  .display-under-800 {
    display: inline-block;
  }
  .hide-under-800 {
    display: none;
  }
  .w-280-to-100-P {
    width: 100%;
  }
  .mq-max-w-600 {
    max-width: 600px !important;
  }

  .mq-w-100-vw {
    width: 100vw;
  }

  .mq-padding-left-15 {
    padding-left: 15px;
  }
  .mq-w-95-vw {
    width: 90vw;
  }
}

@media only screen and (max-width: 865px) {
  .stacking-inputs {
    height: 125px;
  }

  .mq-m-left-21 {
    margin-left: 21px;
  }
}
@media only screen and (max-width: 950px) {
  .mq-fs-20 {
    font-size: 20px !important;
  }
  .mq-w-200 {
    width: 200px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .hide-under-1100 {
    display: none;
  }
  .display-under-1100 {
    display: block;
  }
}
@media only screen and (max-width: 1140px) {
  .mq-w-100-P-1140 {
    width: 100% !important;
  }
  .mq-m-right-0-1140 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .mq-flex-align-left-at-1200 {
    align-items: baseline !important;
  }
  .divider {
    width: 550px;
  }
  .w-100-P-on-1200 {
    width: 100%;
  }
  .common_inputStyle {
    float: left;
  }
  .w-864 {
    width: 500px;
    height: 50px;
  }
  .three-column-parent-grid {
    overflow-x: auto;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: black !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color:black;
}

.ant-radio:hover .ant-radio-inner {
  border-color: black ;
}

.ant5-form-item-explain-error {
  text-align: start;
}
