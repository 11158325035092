.cb-payment-request-card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.11);
  width: 100%;
}

.cb-payment-request-card--header {
  position: relative;
  padding: 12px 16px 0px;
}

.cb-payment-request-card--footer {
  padding: 0 16px 12px;
}

.cb-payment-request-card--amount {
  font-weight: 500;
  font-size: 16px;
  color: #231F20;
  margin: 0;
}

.cb-payment-request-card--description {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.cb-payment-request-card--date {
  font-weight: 400;
  font-size: 13px;
  color: #9C9C9C;
  margin: 0;
}

.cb-payment-request-card--hr {
  height: 1px;
  background-color: #F3F3F3;
  border: none;
  /* max-width: 460px; */
}

.cb-payment-request-card--status {
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  padding: 6px 12px;
  position: absolute;
  top: 12px;
  right: 16px;
}

.cb-payment-request-card--reminder {
  background: #EBEBEB;
  color: #9C9C9C;
}

.cb-payment-request-card--sent {
  background: #FFE5D3;
  color: #FC8333;
}

.cb-payment-request-card--paid {
  background: #ECF8DD;
  color: #7FBD31;
}

.cb-payment-request-card--canceled {
  background: #FFE5D3;
  color: #FC8333;
}

.cb-payment-request-card--refunded {
  background: #EBEBEB;
  color: #9C9C9C;
}

.cb-payment-request-card--payment-failed {
  background: #F9E0E0;
  color: #FF7A7A;
}

.cb-payment-request-card--refund-failed {
  background: #F9E0E0;
  color: #FF7A7A;
}

.cb-payment-request-card--viewed {
  background: #EBEBEB;
  color: #9C9C9C;
}

.cb-payment-request-card--expired {
  background: #F9E0E0;
  color: #FF7A7A;
}

.cb-payment-request-card--chargeback {
  background: #EBEBEB;
  color: #9C9C9C;
}

.cb-payment-request-card--button {
  padding: 0;
  font-weight: 600;
  font-size: 13px;
  color: #33AAFC;
  height: auto;
}

