.login-top-left-logo {
  position: absolute;
  left: 37px;
  top: 46px;
}
.login-title {
  font-family: Poppins;
  color: #353538;
  font-size: 54px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 16px;
}

.left-login-panel {
  float: left;
  width: 50vw;
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/illustration-desktop.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 100vh;
}

.right-login-panel {
  float: right;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.right-login-panel img {
  width: 193px;
  height: 46px;
}

.login-inputs input {
  display: block;
  font-family: Poppins;
  width: calc(402px - 45px);
  height: 50px;
  font-size: 16px;
  border-radius: 3px;
  border: solid 1px #000000;
}

.emailDiv {
  margin-bottom: 20px;
}
.emailDiv input {
  padding-left: 45px;
  font-size: 16px;
  outline: none;
}
.emailDiv span {
  background: url("https://dashboard-v2-images.s3.amazonaws.com/ic-envelope.svg") no-repeat scroll;
  background-position: center center;
  width: 20px;
  height: 14px;
  margin-left: 0;
  position: absolute;
  left: 14px;
  bottom: 0;
  top: 0;
  margin: auto;
}

.passwordDiv input {
  padding-left: 45px;
  font-size: 16px;
  outline: none;
}
.passwordDiv span {
  background: url("https://dashboard-v2-images.s3.amazonaws.com/ic-lock.svg") no-repeat scroll;
  background-position: center center;
  width: 20px;
  height: 20px;
  margin-left: 0;
  position: absolute;
  left: 14px;
  bottom: 0;
  top: 0;
  margin: auto;
}

.login-optional {
  width: 402px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  padding-top: 35px;
  padding-bottom: 39px;
  font-size: 16px;
}

.login-optional img {
  vertical-align: middle;
  margin-right: 17px;
  width: 20px;
  height: 20px;
}

.login-button {
  font-size: 16px;
  color: white;
  font-family: Poppins;
  font-weight: 600;
  width: 402px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 3px;
}

.login-button:disabled {
  opacity: 0.6;
}

.error-text {
  margin-top: 0px;
  color: #ff6363 !important;
}

.login-help {
  color: #1769af;
  font-size: 16px;
}

.login-help-mobile {
  display: none;
  color: #1769af;
}

.info-footer {
  position: absolute;
  width: 48vw;
  height: 20px;
  color: #bdbdbd;
  font-size: 14px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  padding: 39px;
}

.info-footer a {
  color: #bdbdbd;
}
.info-footer a:hover {
  color: #1769af;
}

.info-footer span:nth-child(odd) {
  float: left;
}
.info-footer span:nth-child(odd) a {
  margin-left: 34px;
}
.info-footer span:nth-child(even) {
  float: right;
}

.pw-reset-background {
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/pwreset.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* input on reset password */
.pw-reset-input {
  padding-left: 18px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px#818181;
  outline: none;
  box-sizing: border-box;
}

.maw-90-P {
  max-width: 90%;
}

@media only screen and (max-width: 1450px) {
  .info-footer span:nth-child(odd) {
    float: none;
    display: block;
  }
  .info-footer span:nth-child(odd) a {
    margin-left: 17px;
    margin-right: 17px;
  }
  .info-footer span:nth-child(even) {
    float: none;
    display: block;
  }
}

@media only screen and (max-width: 890px) {
  .login-top-left-logo {
    display: none;
  }

  .info-footer {
    display: none;
  }

  .left-login-panel {
    width: 100vw;
    object-fit: contain;
    z-index: -1;
    float: none;
    position: absolute;
    left: 0px;
    top: -150px;
  }

  .right-login-panel {
    /* left: calc(50% - (319px/2)); */
    /* width: 319px; */
    width: 100vw;
    height: 460px;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    background-color: white;
    justify-content: flex-end;
  }

  .right-login-panel img {
    width: 128px;
    height: 30px;
  }

  .login-optional {
    font-size: 14px;
    width: 289px;
    padding-top: 0px;
    padding-bottom: 24px;
  }
  .login-optional img {
    vertical-align: middle;
    margin-right: 17px;
    width: 20px;
    height: 20px;
  }

  .login-help {
    color: #1769af;
    display: none;
  }
  .login-help-mobile {
    font-size: 14px;
    margin-top: 27px;
    margin-bottom: 30px;
    display: block;
  }

  .login-title {
    font-size: 30px;
    /* margin-bottom: 22px; */
    margin-top: 15px;
  }

  .login-inputs input {
    width: calc(289px - 45px);
  }

  .login-button {
    width: 290px;
    font-size: 14px;
  }
  .passwordDiv {
    margin-bottom: 20px;
  }
  .emailDiv input {
    font-size: 14px;
  }
  .passwordDiv input {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .pw-reset-input {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
