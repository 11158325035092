.bank-accounts-list__header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bank-accounts-list__empty {
  margin-top: 24px;
  padding: 30px 40px 60px;
  background: #f2f3f6;
}

.bank-accounts-list__empty > h3,
.bank-accounts-list__empty > p {
  max-width: 260px;
  margin: 0 auto;
}


.bank-accounts-list__action-button:hover:not([disabled]) {
  background-color: #e8f6ff;
}
