/* stylelint-disable selector-class-pattern */
.cb-bank-account-details__body {
  width: 100%;
  max-width: 320px;
  text-align: left;
}

.cb-bank-account-details__container {
  display: flex;
  margin-bottom: 24px;
}

.cb-bank-account-details__container > div:first-child {
  flex: 1;
  text-align: left;
}

.cb-bank-account-details__container > div:last-child {
  text-align: right;
}

.cb-bank-account-details__container > div:last-child > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cb-bank-account-details__container > div:last-child svg {
  background-color: transparent !important;
}

.cb-bank-account-details__container > div:last-child button:not([disabled]):hover {
  background: #f2f3f6 !important;
  color: #bdbdbd !important;
}

.cb-bank-account-details__title {
  color: #000;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
}

.cb-bank-account-details__subtitle {
  color: #000;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
}

.cb-bank-account-details__text {
  color: #000;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 15px;
}

.cb-bank-account-details__footer {
  display: flex;
  justify-content: right;
}

.cb-bank-account-details__dropdown-label {
  display: block;
  margin-bottom: 8px;
  color: #000;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
}

.unassignedStoreDropdown{
  height: 34px;
  background-color: #ebebeb;
  width: 160px;
  line-height: 31px;
  padding-left: 13px;
  color: #BDBDBD;
  margin: 0px;
  border-radius: 4px;
}
.unassignedStoreDropdownDiv{
  width: 332px;
  height: 35px;
  border: 1px solid #F2F3F6;
  border-radius: 4px;
  padding: 7px;
}
