/* main content */

.table-head {
  cursor: pointer;
}

.hidden {
  display: none;
}

.table-head th {
  color: #565656;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 20px;
}

.table-row {
  overflow-y: scroll;
}

.table-row td {
  padding-top: 11px;
  padding-bottom: 12px;
  text-align: left;
}

.table-row .no-y-padding {
  padding-bottom: 0px;
  padding-top: 0px;
}

.body-row:hover {
  background-color: #f4f4f4;
}

.body-row {
  font-size: 12px;
  font-weight: normal;
}

.table {
  /* border: 1.0px solid var(--darkGrey); */
  margin-top: 30px;

  overflow-x: scroll;
  width: 100%;
  max-width: 1200px;
  position: relative;
  /* max-height: 1250px; */
  /* overflow-y: initial; */
  -ms-overflow-style: none;
}

.table-big {
  max-width: none !important;
}

.table::-webkit-scrollbar {
  display: none;
}

.table-row.highlight {
  background: var(--darkGrey);
}

.real-table {
  width: 100%;
  border-collapse: collapse;
}

.real-small-table {
  margin-right: 13px;
  /* width: 95%; */
  border-collapse: collapse;
}

#tableScroll {
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

#tableScroll::-webkit-scrollbar {
  display: none;
}

#tableScroll {
  -ms-overflow-style: none;
}

.table-body-content {
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  overflow-x: hidden;
  /* margin-top: 30px; */
  /* width: calc(100vw - 630px); */
  /* max-height: 100%; */
  max-width: 1200px;
  /* margin-bottom: 50px; */
  /* overflow-y: scroll; //TODO: figure out if this breaks anything */
}

.table-content-tabbed-head {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  margin: 0px !important;
  height: auto;

  /* width: 100%; */
  /* max-height: 1200px; */
  max-width: 1200px;
  /* border: none !important; */
}

.body-head-search::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #818181;
}

.body-head-search {
  z-index: 1;
  border-radius: 3px;
  border: none;
  width: 330px;
  height: 50px;
  padding-top: 0px;
  padding-left: 50px;
  background-color: #f4f4f4;
  background-image: url("https://dashboard-v2-images.s3.amazonaws.com/ic-search.svg");
  background-repeat: no-repeat;
  /* background-position: 15px 14px; */
  background-position: 20px 16px;
  outline: none;
  margin-left: 2px;
}

.table-body-header {
  z-index: 0 !important;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.pagination {
  background-color: #f3f3f3;
  height: 57px;
  max-width: calc(100% - 1px);
  width: calc(99.9% - 1px);
  /* max-width: 1197px; */
  overflow-x: 'hidden';
  bottom: 1px;
  /* margin-left: 2px; */
  position: relative;
  display: flex;
  padding-top: 7px;
  /* border-radius: 0px 0px 5px 5px; */

  /* border-right: 1.0px solid var(--darkGrey); */
  /* border-left: 1.0px solid var(--darkGrey); */
}

.pagination-container {
  background-color: #f3f3f3;
  height: 56px;
  width: 100%;
  max-width: 1200px;
  bottom: 1px;
  padding-top: 7px;
  padding-left: 20px;
  /* margin-left: 1px; */
  position: absolute;
  display: flex;
}

.pagination-container p {
  padding: 10px 15px;
}

.pagination-gray {
  display: none;
  position: absolute;
  z-index: 3;
  height: 3px;
  width: 23px;
  background-color: #565656;
  margin-left: 12px;
  margin-bottom: 0px;
  top: 0;
}

.pagination-li div {
  display: block;
}

.pagination-ul {
  margin-top: 8px;
  display: flex;
  list-style: none;
  padding-left: 0px;
}

.pagination-li {
  font-size: 12px;
  width: 40px;
  padding-top: 5px;
}

.pagination img {
  padding: 15px 15px;
}

.pagination p {
  padding: 10px 15px;
}

.pagination span {
  display: inline !important;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

.pagenum-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1vh;
  right: 1.5vw;
  margin-bottom: 15px;
}

#page-num {
  opacity: 0.5;
}

#page-num1 {
  opacity: 0.5;
}

#page-num-active {
  opacity: 1;
}

#page-num-active1 {
  opacity: 1;
}

.pagenum {
  z-index: 2;
  width: 110px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  outline: none;
}

.pag-button {
  border: none;
  position: absolute;
  right: 0px;
}

@media only screen and (max-width: 940px) {
  .table-body-header input {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .table-body-header {
    z-index: 0 !important;
    width: 100%;
    height: auto;
  }

  .table-body-header a {
    width: 215px;
  }
}

@media only screen and (max-width: 800px) {
  .table-body-content {
    width: calc(100vw - 49px) !important;
    /* margin-left: 20px; */
  }
}

@media only screen and (max-width: 1250px) {
  .table {
    width: 100%;
  }
  .pagination {
    width: 100%;
    padding-left: 0px;
  }

  .pagination p {
    padding: 10px 15px;
  }

  .pagination-blue {
    margin-left: 9px;
  }

  .pagination-ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
  }

  .pagination-li {
    width: 40px;
    font-weight: 500;
  }
  .pagenum-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1vh;
    right: 1.5vw;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1518px) {
  .table-body-content {
    width: 100%;
  }

  .real-small-table {
    margin-right: 13px;
    width: 97.5%;
    border-collapse: collapse;
  }
}
