.cb-client-collection-modal .ant-modal-content {
  text-align: center;
  max-width: 348px;
}

.cb-client-collection-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.cb-client-collection-modal__title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.cb-client-collection-modal__client-input {
  margin: 32px 0;
}

.cb-client-collection-modal__collection-input {
  margin-bottom: 32px;
}

.cb-client-collection-modal .cb-client-card {
  margin: 18px 0;
}
