.cd-automessages-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.11);
  padding: 8px 30px;
  margin-bottom: 10px;
  gap: 5px;
  position: relative;

  > span {
    color: #231F20;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: justify;
  }
  > .cd-automessages-item-description {
    color: #9C9C9C;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    > svg {
      margin-right: 14px;
    }
  }

  > .cd-automessages-item-action {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 14px;
    color: #9C9C9C;
    cursor: pointer;
  }
}
