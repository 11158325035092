.clients-v2-collection-products--list-items {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 16px;
  padding-right: 4px;
}

.clients-v2-collection-products--list-pagination {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}

.clients-v2-collection-products--list-subtitle {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #818181;
}
